import React, { useState } from "react";
import "../../Css/createAudio.css";
import configData from "../../config.json";
import { questionTypes } from "../utilities/data/data";
import { useNavigate } from "react-router-dom";
import { FaInfoCircle } from "react-icons/fa";
import FormWrapper from "./add-question/formWrapper";
import formWrapperImage from "../../img/examHub.png";
import { questionGeneratorTexts } from "../utilities/data/formText";


const CreateQuestion = ({ isOpen }) => {
  const [step, setStep] = useState(1);
  const [file, setFile] = useState(null);
  const [AllPages, setAllPages] = useState(true);
  const [numberOfPages, setNumberOfPages] = useState("");
  const [optionsPerPage, setOptionsPerPage] = useState();
  const [questionsPerPage, setQuestionsPerPage] = useState();
  const [questionCategory, setQuestionCategory] = useState({});
  const [difficulty, setDifficulty] = useState();
  const [hoveredStyle, setHoveredStyle] = useState(null);
  const [selectedOption, setSelectedOption] = useState("");
  const navigate = useNavigate();

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const nextStep = () => {
    setStep((prevStep) => prevStep + 1);
  };

  const prevStep = () => {
    setStep((prevStep) => prevStep - 1);
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handlePagesToggleChange = () => {
    setAllPages(!AllPages);
    setNumberOfPages("");
  };
  const handlequestionCategoryChange = (event, state) => {
    if (state === "difficulty") {
      setDifficulty(event.target.value);
    } else {
      setQuestionCategory(event.target.value);
    }
  };

  const isValidJSON = (str) => {
    try {
      JSON.parse(str);
      return true;
    } catch (e) {
      return false;
    }
  };

  return (
    <div
      className={`audio-creation-form-wrapper ${isOpen ? "open" : "close"}`}
    >
      <FormWrapper
        isOpen={isOpen}
        texts={questionGeneratorTexts}
        configData={configData}
        background={configData.AppColor3}
        formWrapperImage={formWrapperImage}
      >
      <div className= {`audio-creation-form`} style={{marginBottom: '280px'}}>
        {step === 1 && (
          <div className="radio-group-create">
            <h2>What would you like to do?</h2>
            <div>
              <label>
                <input
                  type="radio"
                  value="option1"
                  checked={selectedOption === "option1"}
                  onChange={handleOptionChange}
                />
                Generate Questions
              </label>
              <br />
              <label>
                <input
                  type="radio"
                  value="option2"
                  checked={selectedOption === "option2"}
                  onChange={handleOptionChange}
                />
                Add questions
              </label>
            </div>
            <button
              onClick={() => {
                if (selectedOption === "option2") {
                  navigate("/question/add-question");
                } else if (selectedOption === "option1") {
                  nextStep();
                } else {
                  alert(
                    "You forgot to select what you want to do. We assume you are here to generate questions."
                  );
                  nextStep();
                }
              }}
              className="btn"
              style={{
                background: configData.AppColor3,
                alignSelf: "flex-end",
              }}
            >
              Next
            </button>
          </div>
        )}
        {step === 2 && (
          <div className="form-step">
            <h2>Create Question</h2>
            <textarea
              placeholder="Paste your material here..."
              className="textarea"
            ></textarea>
            <h3 style={{ alignSelf: "center", margin: "20px auto" }}>Or</h3>
            <div className="file-input-container">
              <input
                type="file"
                id="file"
                accept=".pdf,.doc,.docx,.json,.txt,.html,.csv,.ppt,.pptx"
                onChange={handleFileChange}
                className="file-input"
              />
              <label
                htmlFor="file"
                className="file-input-label"
                style={{ background: configData.AppColor3, color: "#fff" }}
              >
                Choose a file
              </label>
              {file && <p className="file-name">Selected file: {file.name}</p>}
            </div>
            <div className="audio-button-wrapper">
              <button
                onClick={prevStep}
                className="btn"
                style={{ background: configData.AppColor3 }}
              >
                Back
              </button>
              <button
                onClick={nextStep}
                className="btn"
                style={{ background: configData.AppColor3 }}
              >
                Next
              </button>
            </div>
          </div>
        )}
        {step === 3 && (
          <div className="form-step">
            <h2>Question Preferences</h2>
            <label>
              Question Type <span style={{ color: "red" }}> *</span>
            </label>
            <div className="styled-select-container">
              <select
                value={questionCategory}
                onChange={handlequestionCategoryChange}
                className="styled-select"
              >
                {questionTypes.map((questionType) => (
                  <option
                    key={questionType.value}
                    value={JSON?.stringify(questionType)}
                  >
                    {questionType.label}
                  </option>
                ))}
              </select>
              <FaInfoCircle
                className="info-icon"
                style={{ color: configData.AppColor3 }}
                onMouseEnter={() => {
                  if (isValidJSON(questionCategory)) {
                    setHoveredStyle(JSON.parse(questionCategory));
                  } else {
                    setHoveredStyle({
                      value: "multiple-choice",
                      label: "Multiple Choice Questions",
                      description:
                        "A type of question where respondents are provided with multiple answer options, typically only one of which is correct. Useful for assessing knowledge on specific topics with clear, predefined answers.",
                    });
                  }
                }}
                onMouseLeave={() => setHoveredStyle(null)}
              />
              {hoveredStyle ? (
                <div className="audio-tooltip" style={{ top: "80%", right: 0 }}>
                  {hoveredStyle?.description}
                </div>
              ) : (
                ""
              )}
            </div>
            <label>Difficulty</label>
            <select
              value={difficulty}
              onChange={(e) => handlequestionCategoryChange(e, "difficulty")}
              className="styled-select"
            >
              <option key={"Easy"} value={"Easy"}>
                Easy
              </option>
              <option key={"Medium"} value={"Medium"}>
                Medium
              </option>
              <option key={"Hard"} value={"Hard"}>
                Hard
              </option>
            </select>
            <label>Number of Questions Per Page</label>
            <input
              type="number"
              placeholder="5"
              value={questionsPerPage}
              max={20}
              onChange={(e) => {
                setQuestionsPerPage(e.target.value);
              }}
            />
            <label>Number of Options Per Question</label>
            <input
              type="number"
              placeholder="4"
              value={optionsPerPage}
              max={6}
              onChange={(e) => {
                setOptionsPerPage(e.target.value);
              }}
            />
            <label>Number of pages to convert:</label>
            <input
              type="number"
              placeholder="Number of Pages"
              value={numberOfPages}
              onChange={(e) => {
                setAllPages(false);
                setNumberOfPages(e.target.value);
              }}
            />
            <label className="toggle-label" style={{marginTop: '10px'}}>
              All pages:
              <input
                type="checkbox"
                checked={AllPages}
                onChange={handlePagesToggleChange}
                className="toggle-input"
              />
              <span
                className="toggle-slider"
                style={{ background: AllPages ? configData.AppColor3 : "" }}
              ></span>
            </label>

            <div className="audio-button-wrapper">
              <button
                onClick={prevStep}
                className="btn"
                style={{ background: configData.AppColor3 }}
              >
                Back
              </button>
              <button
                onClick={nextStep}
                className="btn"
                style={{ background: configData.AppColor3 }}
              >
                Next
              </button>
            </div>
          </div>
        )}

        {step === 4 && (
          <div className="form-step">
            <h2>Conversion Complete</h2>
            <p>Your questions has been successfully created!</p>
            <div className="audio-button-wrapper">
              <button
                onClick={prevStep}
                className="btn"
                style={{ background: configData.AppColor3 }}
              >
                Back
              </button>
              <button
                onClick={() =>
                  (window.location.href = "/question/my-questions")
                }
                className="btn"
                style={{ background: configData.AppColor3 }}
              >
                View questions
              </button>
            </div>
          </div>
        )}
      </div>
      </FormWrapper>
    </div>
  );
};

export default CreateQuestion;
