import React, { useState } from "react";
import "../../Css/createAudio.css";
import configData from "../../config.json";
import { FaInfoCircle } from "react-icons/fa";
import { styles } from "../utilities/data/data";
import FormWrapper from "../questionScreens/add-question/formWrapper";
import formWrapperImage from "../../img/AudioHub.png";
import { audioHubTexts } from "../utilities/data/formText";
import { Link } from "react-router-dom";
// import {
//   checkIfPdfIsMachineReadable,
//   checkDocxReadability,
//   getFileExtension,
// } from "../utilities/helperFunc/audio";

const CreateAudio = ({ isOpen }) => {
  const [step, setStep] = useState(1);
  const [file, setFile] = useState(null);
  const [AllPages, setAllPages] = useState(true);
  const [numberOfPages, setNumberOfPages] = useState("");
  const [voiceActor, setVoiceActor] = useState("actor1");
  const [style, setStyle] = useState({});
  const [hoveredStyle, setHoveredStyle] = useState(null);
  // eslint-disable-next-line
  const [costs, setCosts] = useState([`Kingsheart's Vat: 🤍 0`]);
   // eslint-disable-next-line
  const [costValue, setCostValue] = useState([0]);

  const nextStep = () => {
    setStep((prevStep) => prevStep + 1);
  };

  const prevStep = () => {
    setStep((prevStep) => prevStep - 1);
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handlePagesToggleChange = () => {
    setAllPages(!AllPages);
    setNumberOfPages("");
  };
  const handleVoiceActorChange = (event) => {
    setVoiceActor(event.target.value);
  };
  const handleStyleChange = (event) => {
    setStyle(event.target?.value);
  };
  const isValidJSON = (str) => {
    try {
      JSON.parse(str);
      return true;
    } catch (e) {
      return false;
    }
  };

  // const checkIfOcrIsNotNeeded = async (document) => {
  //   if (
  //     [".txt", ".csv", ".json", ".xml", ".html"].includes(document.extension)
  //   ) {
  //     return false;
  //   }
  //   if (getFileExtension(document.name) === "pdf") {
  //     const ocrNotNeeded = await checkIfPdfIsMachineReadable(document);
  //     console.log(ocrNotNeeded);
  //     return ocrNotNeeded;
  //   }
  //   if (getFileExtension(document.name) === "docx") {
  //     const ocrNotNeeded = await checkDocxReadability(document);
  //     console.log("ocrNotNeeded: ", ocrNotNeeded);
  //     return ocrNotNeeded;
  //   }

  //   return false;
  // };

  const handleSubmit = async () => {
    //1 checkIfOcrIsNeeded - the first thing is to check if ocr is even needed at all
    // const ocrIsNotNeeded = await checkIfOcrIsNotNeeded(file);
    // console.log(file, "ocrIsNotNeeded: ", ocrIsNotNeeded);
  };

  return (
    <div className={`audio-creation-form-wrapper ${isOpen ? "open" : "close"}`}>
      <FormWrapper
        isOpen={isOpen}
        texts={audioHubTexts}
        background={configData.AppColor}
        configData={configData}
        formWrapperImage={formWrapperImage}
      >
        <div
          className={`audio-creation-form`}
          style={{ marginBottom: "150px" }}
        >
          {step === 1 && (
            <div className="form-step">
              <h2>Create Audio</h2>
              <textarea
                placeholder="Paste your material here..."
                className="textarea"
              ></textarea>
              <h3 style={{ alignSelf: "center", margin: "20px auto" }}>Or</h3>
              <div className="file-input-container">
                <input
                  type="file"
                  id="file"
                  accept=".pdf,.docx,.json,.txt,.html,.csv,.ppt,.pptx"
                  onChange={handleFileChange}
                  className="file-input"
                />
                <label
                  htmlFor="file"
                  className="file-input-label"
                  style={{ background: configData.AppColor, color: "#fff" }}
                >
                  Choose a file
                </label>
                {file && (
                  <p className="file-name">Selected file: {file.name}</p>
                )}
              </div>
              <button
                onClick={nextStep}
                className="btn"
                style={{ background: configData.AppColor }}
              >
                Next
              </button>
            </div>
          )}
          {step === 2 && (
            <div className="form-step">
              <h2>Audio Preferences</h2>
              <label>Voice Actor:</label>
              <select
                value={voiceActor}
                onChange={handleVoiceActorChange}
                className="styled-select"
              >
                <option value="actor1">Voice Actor 1</option>
                <option value="actor2">Voice Actor 2</option>
                <option value="actor2">Voice Actor 3</option>
                <option value="actor2">Voice Actor 4</option>
                <option value="actor2">Voice Actor 5</option>
                <option value="actor2">Voice Actor 6</option>
                <option value="actor2">Voice Actor 7</option>
              </select>
              <label>Amount of Material to Convert:</label>
              <input
                type="number"
                className="audio-input-number"
                placeholder="Number of Pages"
                value={numberOfPages}
                onChange={(e) => {
                  setAllPages(false);
                  setNumberOfPages(e.target.value);
                }}
              />
              <label className="toggle-label">
                All pages:
                <input
                  type="checkbox"
                  checked={AllPages}
                  onChange={handlePagesToggleChange}
                  className="toggle-input"
                />
                <span className="toggle-slider"></span>
              </label>
              <label>Style:</label>
              <div className="styled-select-container">
                <select
                  value={style}
                  onChange={handleStyleChange}
                  className="styled-select"
                >
                  {styles.map((style) => (
                    <option key={style.value} value={JSON?.stringify(style)}>
                      {style.label}
                    </option>
                  ))}
                </select>
                <FaInfoCircle
                  className="info-icon"
                  style={{ color: configData.AppColor }}
                  onMouseEnter={() => {
                    if (isValidJSON(style)) {
                      setHoveredStyle(JSON.parse(style));
                    } else {
                      setHoveredStyle({
                        value: "none",
                        label: "None",
                        description: "No specific style applied.",
                      });
                    }
                  }}
                  onMouseLeave={() => setHoveredStyle(null)}
                />
                {hoveredStyle ? (
                  <div className="audio-tooltip">
                    {hoveredStyle?.description}
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="audio-button-wrapper">
                <button
                  onClick={prevStep}
                  className="btn"
                  style={{ background: configData.AppColor }}
                >
                  Back
                </button>
                <button
                  onClick={nextStep}
                  className="btn"
                  style={{ background: configData.AppColor }}
                >
                  Next
                </button>
              </div>
            </div>
          )}
          {step === 3 && (
            <div className="form-step">
              <h2>Cost Evaluation</h2>
              {costs.map((cost) => (
                <p>{cost}</p>
              ))}
              <p>Estimated Cost: 🤍 {costValue}</p>
              <label className="toggle-label">
                Batch Api:
                <input
                  type="checkbox"
                  checked={AllPages}
                  onChange={handlePagesToggleChange}
                  className="toggle-input"
                />
                <span className="toggle-slider"></span>
              </label>
              <p>
                Note: Conversions using the batch API may take up to 24 hours.
                You will be notified once your audio is ready.
              </p>
              <label>
                <input
                  type="checkbox"
                  checked={AllPages}
                  onChange={handlePagesToggleChange}
                  style={{ marginRight: "5px", display: "inline-block" }}
                />
                i agree to the{" "}
                <Link style={{ marginLeft: "3px" }} to="/">
                  terms of use
                </Link>
              </label>
              <div className="audio-button-wrapper">
                <button
                  onClick={prevStep}
                  className="btn"
                  style={{ background: configData.AppColor }}
                >
                  Back
                </button>
                <button
                  onClick={nextStep}
                  className="btn"
                  style={{ background: configData.AppColor }}
                >
                  create
                </button>
              </div>
            </div>
          )}
          {step === 4 && (
            <div className="form-step">
              <h2>Conversion Complete</h2>
              <p>Your audio has been successfully converted!</p>
              <div className="audio-button-wrapper">
                <button
                  onClick={prevStep}
                  className="btn"
                  style={{ background: configData.AppColor }}
                >
                  Back
                </button>
                <button
                  onClick={() => (handleSubmit)}
                  className="btn"
                  style={{ background: configData.AppColor }}
                >
                  Listen to Audio
                </button>
              </div>
            </div>
          )}
        </div>
      </FormWrapper>
    </div>
  );
};

export default CreateAudio;
