import React, { useState, useEffect, useRef } from "react";
import configData from "../../../config.json";
import "../../../Css/addQuestion.css"; // Import the CSS file

const Step4ExamDetails = ({ examDetails, onInputChange, onPrev, onSubmit }) => {
  const [difficultyType, setDifficultyType] = useState('');
  const handleDifficultyTypeChange = (event) => {
      setDifficultyType(event.target.value);
    }

    const formStepRef = useRef(null);
    useEffect(() => {
      const formStepElement = formStepRef.current;
    let scrollbarTimeout;
    
      function resetScrollbarTimeout() {
        formStepElement.classList.remove('inactive-scrollbar');
        clearTimeout(scrollbarTimeout);
        scrollbarTimeout = setTimeout(() => {
          formStepElement.classList.add('inactive-scrollbar');
        }, 2000);
      }
    
      formStepElement.addEventListener('scroll', resetScrollbarTimeout);
      formStepElement.addEventListener('mousemove', resetScrollbarTimeout);
    
      // Call the function to initialize the scrollbar state
      resetScrollbarTimeout();
    
      // Cleanup function to remove event listeners when the component unmounts
      return () => {
        clearTimeout(scrollbarTimeout);
        formStepElement.removeEventListener('scroll', resetScrollbarTimeout);
        formStepElement.removeEventListener('mousemove', resetScrollbarTimeout);
      };
    }, []);
    
 const difficultyTypes = ['easy', 'medium', 'hard']
  return( <>
  <div className="form-step" style={{overflowY: 'auto'}} ref={formStepRef}>
    <h2>Exam Details</h2>
    <div className="addQuestion-input-container">
      <label>
        How should we name this assessment?{" "}
        <span style={{ color: "red" }}> *</span>
      </label>
      <input
        type="text"
        name="name"
        value={examDetails.name}
        onChange={onInputChange}
        placeholder="Mch221 introductory mechanics"
        className="exam-detail-input"
      />
      <label>
        How should we describe this assessment?{" "}
        <span style={{ color: "red" }}> *</span>
      </label>
      <input
        type="text"
        name="description"
        value={examDetails.description}
        onChange={onInputChange}
        placeholder="introductory mechanics description.."
        className="exam-detail-input"
      />
      <label>
        What is the time frame for the assessment?(in minutes){" "}
        <span style={{ color: "red" }}> *</span>
      </label>
      <input
        type="number"
        name="duration"
        value={examDetails.duration}
        onChange={onInputChange}
        placeholder="30"
        className="addquestion-input-number"
      />
      <label>
        What grade is the assessment intended for?{" "}
        <span style={{ color: "red" }}> *</span>
      </label>
      <input
        type="text"
        name="grade"
        value={examDetails.grade}
        onChange={onInputChange}
        placeholder="Students in their second year of college."
        className="exam-detail-input"
      />
      <label>
        Which institution is this for?{" "}
        <span style={{ color: "red" }}> *</span>
      </label>
      <input
        type="text"
        name="school"
        value={examDetails.school}
        onChange={onInputChange}
        placeholder="University of Nigeria Nsukka"
        className="exam-detail-input"
      />
      <label>
        How will you describe the difficulty of the assessment?{" "}
        <span style={{ color: "red" }}> *</span>
      </label>
      <div className="styled-select-container">
        <select
          className="styled-select"
          value={difficultyType}
          onChange={handleDifficultyTypeChange}
          style={{
            borderRadius: '10px'
          }}
        >
          {difficultyTypes.map((difficultyType) => (
            <option
              key={difficultyType}
              value={JSON?.stringify(difficultyType)}
            >
              {difficultyType}
            </option>
          ))}
        </select>
      </div>
    </div>
    <div className="button-wrapper">
      <button
        style={{ background: configData.AppColor3 }}
        className="navigation-button"
        onClick={onPrev}
      >
        Back
      </button>
      <button
        style={{ background: configData.AppColor3 }}
        className="navigation-button"
        onClick={onSubmit}
      >
        Next
      </button>
    </div>
  </div>
    
    </>
  )
};

export default Step4ExamDetails;
