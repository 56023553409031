const step1Texts = [
  "Choose your preferred method for adding questions, tailored to your needs and workflow.",
  "Ordered documents: Upload structured files like Excel or JSON. Perfect for when you have a predefined format.",
  "Unordered documents: Upload unstructured files like PDF or DOCX. Great for scanned notes or written questions, we will extract the questions from your document.",
  "Add manually: Use a form to input questions one by one. Ideal for adding specific questions.",
  "Add images: Enhance your questions by including images to provide visual context or details.",
];

const audioHubTexts = [
  "Transform idle moments into learning opportunities with Audio Hub.",
  "Seamlessly integrate educational content into your daily routine, from walking to cooking.",
  "Maximize your time by listening to textbook content anytime, anywhere.",
  "Leverage every spare moment for personal growth by accessing your audio textbooks through Audio Hub.",
  "With Audio Hub, your educational journey is just a play button away—transform textbooks into captivating audio experiences."
];
const questionGeneratorTexts = [
  "Dive into the essence of your study material with our smart Question Generator.",
  "Effortlessly transform any text into a quiz with our intuitive question-creation tool.",
  "Elevate your learning experience by generating custom quizzes from your reading materials.",
  "Our Question Generator is your academic ally, turning dense texts into interactive learning sessions."
];

const customExamTexts = [
  "Navigate your syllabus with precision using 'Custom Exam' mode—select questions that target your immediate learning needs.",
  "Challenge yourself with 'Custom Exam'; mix and match topics to create a diverse testing experience that covers all your bases.",
  "Set your own pace with 'Custom Exam'—adjust the difficulty to suit your comfort level and gradually increase it as you improve.",
  "Discover a new way to review with 'Custom Exam'; focus on areas you're curious about and turn weaknesses into strengths.",
  "Reinvent test preparation with 'Custom Exam'—combine various question types to simulate real exam conditions and build confidence."
];

const topicByTopicTexts = [
  "Explore subjects in depth with 'Topic-by-Topic' mode—dive into a series of questions designed to thoroughly cover each area of interest.",
  "Build a solid foundation in any subject by engaging with 'Topic-by-Topic' mode, where focused questions make mastering concepts easier.",
  "Incrementally advance your knowledge with 'Topic-by-Topic'—a structured approach to learning that ensures no detail is overlooked.",
  "Enhance your expertise systematically with 'Topic-by-Topic', where each question leads you closer to complete topic comprehension.",
  "Streamline your study sessions with 'Topic-by-Topic' mode—efficiently navigate through subjects, one topic at a time."
];




module.exports = { step1Texts, audioHubTexts, questionGeneratorTexts, topicByTopicTexts, customExamTexts };
