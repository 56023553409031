import React, { useState, useEffect, useMemo, useCallback, useRef } from "react";
import { FaPlus, FaTrash } from "react-icons/fa";
import { Tooltip } from "react-tooltip";
import "../../../Css/MCQForm.css";
import configData from "../../../config.json";
import QuestionForm from "./QuestionForm";
import Timeline from "./Timeline";

const MCQForm = ({ type, questionTypes }) => {

  const formStepRef = useRef(null);
    useEffect(() => {
      const formStepElement = formStepRef.current;
    let scrollbarTimeout;
    
      function resetScrollbarTimeout() {
        formStepElement.classList.remove('inactive-scrollbar');
        clearTimeout(scrollbarTimeout);
        scrollbarTimeout = setTimeout(() => {
          formStepElement.classList.add('inactive-scrollbar');
        }, 2000);
      }
    
      formStepElement.addEventListener('scroll', resetScrollbarTimeout);
      formStepElement.addEventListener('mousemove', resetScrollbarTimeout);
    
      // Call the function to initialize the scrollbar state
      resetScrollbarTimeout();
    
      // Cleanup function to remove event listeners when the component unmounts
      return () => {
        clearTimeout(scrollbarTimeout);
        formStepElement.removeEventListener('scroll', resetScrollbarTimeout);
        formStepElement.removeEventListener('mousemove', resetScrollbarTimeout);
      };
    }, []);

  const resizeObserverError = new ErrorEvent("error", {
    message: "ResizeObserver loop completed with undelivered notifications.",
  });
  const suppressResizeObserverError = () => {
    window.addEventListener("error", (e) => {
      if (e.message === resizeObserverError.message) {
        e.stopImmediatePropagation();
      }
    });
  };
  useEffect(() => {
    suppressResizeObserverError();
     // eslint-disable-next-line
  }, [resizeObserverError]);

  const [questions, setQuestions] = useState(() => {
    let savedQuestions = localStorage.getItem("questions");
    const filteredSavedQuestions = savedQuestions
      ? JSON.parse(savedQuestions).filter((question) => question.type === type)
      : [];
    const newQuestion = {
      question: "",
      options: [],
      explanation: "",
      correctOption: type === questionTypes[1].label ? [] : "",
      type: type,
    };

    if (filteredSavedQuestions.length === 0 && savedQuestions) {
      let questionArray = JSON.parse(savedQuestions);
      questionArray.push(newQuestion);
      savedQuestions = JSON.stringify(questionArray);
    }

    return savedQuestions ? JSON.parse(savedQuestions) : [newQuestion];
  });

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

  const compQuestion = useMemo(
    () => questions.filter((question) => question.type === type),
    [questions, type]
  );

  useEffect(() => {
    localStorage.setItem("questions", JSON.stringify(questions));
  }, [questions]);

  const addQuestion = () => {
    const newQuestion = {
      question: "",
      options: [],
      explanation: "",
      correctOption: type === questionTypes[1].label ? [] : "",
      type: type,
    };
    setQuestions((prevQuestions) => [...prevQuestions, newQuestion]);
    setCurrentQuestionIndex(compQuestion.length);
  };

  const deleteQuestion = (index) => {
    if (compQuestion.length > 1) {
      const newQuestions = questions.filter((_, i) => i !== index);
      setQuestions(newQuestions);
      setCurrentQuestionIndex(index > 0 ? index - 1 : 0);
    }
  };

  const handleJumpToQuestion = (index) => {
    setCurrentQuestionIndex(index);
  };

  const updateQuestionField = (index, field, value) => {
    const newQuestions = [...questions];
    const compQIndex = questions.findIndex((q) => q === compQuestion[index]);
    newQuestions[compQIndex] = { ...newQuestions[compQIndex], [field]: value };
    setQuestions(newQuestions);
  };

  const handleOptionSelection = useCallback(
    (qIndex, value) => {
      const newQuestions = [...questions];
      let correctOptionIndex = compQuestion[qIndex].correctOption;
      if (type === "True or False") {
        correctOptionIndex = compQuestion[qIndex].correctOption.indexOf(value);
        if (correctOptionIndex > -1) {
          newQuestions[
            questions.indexOf(compQuestion[qIndex])
          ].correctOption.splice(correctOptionIndex, 1);
        } else {
          newQuestions[
            questions.indexOf(compQuestion[qIndex])
          ].correctOption.push(value);
        }
      } else {
        newQuestions[questions.indexOf(compQuestion[qIndex])].correctOption =
          value;
      }
      setQuestions(newQuestions);
    },
    [questions, compQuestion, type]
  );

  const handleOptionChange = useCallback(
    (qIndex, oIndex, value) => {
      const newQuestions = [...questions];
      const compQIndex = questions.findIndex((q) => q === compQuestion[qIndex]);
      newQuestions[compQIndex].options[oIndex] = value;
      setQuestions(newQuestions);
    },
    [questions, compQuestion]
  );

  const addOption = (qIndex) => {
    const newQuestions = [...questions];
    const compQIndex = questions.findIndex((q) => q === compQuestion[qIndex]);
    if (newQuestions[compQIndex].options.length > 20) {
      return;
    }
    if (type === questionTypes[5].label) {
      newQuestions[compQIndex].options.push("", "");
    } else {
      newQuestions[compQIndex].options.push("");
    }
    setQuestions(newQuestions);
  };

  const deleteOption = useCallback(
    (qIndex, oIndex) => {
      const newQuestions = [...questions];
      const compQIndex = questions.findIndex((q) => q === compQuestion[qIndex]);
      if (
        type === questionTypes[5].label &&
        oIndex === newQuestions[compQIndex].options.length - 1
      ) {
        newQuestions[compQIndex].options.splice(oIndex - 1, 2);
      } else if (type === questionTypes[5].label) {
        newQuestions[compQIndex].options.splice(oIndex, 2);
      } else {
        newQuestions[compQIndex].options.splice(oIndex, 1);
      }
      setQuestions(newQuestions);
    },
    [questions, type, compQuestion, questionTypes]
  );

  return (
    <div className="mcq-form" ref={formStepRef}>
      <div className="question-actions">
        <FaTrash
          data-tooltip-id="delete-tooltip"
          data-tooltip-content="Delete Question"
          className="icon delete-question"
          onClick={() => deleteQuestion(currentQuestionIndex)}
        />
        <FaPlus
          data-tooltip-id="add-tooltip"
          data-tooltip-content="Add Question"
          className="icon add-question"
          onClick={addQuestion}
          style={{ color: configData.AppColor3 }}
        />
        <Tooltip id="delete-tooltip" place="top" effect="solid" />
        <Tooltip id="add-tooltip" place="top" effect="solid" />
      </div>
      <Timeline
        compQuestion={compQuestion}
        currentQuestionIndex={currentQuestionIndex}
        handleJumpToQuestion={handleJumpToQuestion}
      />
      <QuestionForm
        type={type}
        currentQuestionIndex={currentQuestionIndex}
        compQuestion={compQuestion}
        updateQuestionField={updateQuestionField}
        handleOptionSelection={handleOptionSelection}
        handleOptionChange={handleOptionChange}
        addOption={addOption}
        deleteOption={deleteOption}
        configData={configData}
        questionTypes={questionTypes}
      />
    </div>
  );
};

export default MCQForm;
