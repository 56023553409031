import { useEffect,useState,useContext } from 'react';
import {Outlet, useNavigate} from 'react-router-dom'
import Home from '../GeneralScreens/Home';
import instance from '../../Context/axiosConfig';
import { AuthContext } from "../../Context/AuthContext";
import { checkCookie, clearCookie } from '../utilities/helperFunc/chechCookie';
const PrivateRoute =( ) => {
    const bool = checkCookie('token')
    console.log(bool)
    const [auth ,setAuth] = useState(bool)
    const navigate = useNavigate()
    const {setActiveUser, setConfig } = useContext(AuthContext)

    useEffect(() => {

       const controlAuth = async () => {
        const config = {
            headers: {
            "Content-Type": "application/json",
            // authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
        };
        try {
            const response = await instance.get("/auth/private", config); 
            console.log(response.data)
            setAuth(true)
            setActiveUser(response.data.user)
            setConfig(config)

        } 
        catch (error) {
            if(error?.response?.status === 400){
                alert('you need to login to access this route')
            }else if(error?.response?.status === 401){
                setAuth(false)
                setActiveUser({})
                alert('seasoned time out, please login')
                clearCookie('token')
                setTimeout(() => {
                    navigate('/login')
                }, 3000)
            }else{
                alert('you need to login to access this route')
                navigate('/')
            }


        }
        };

        controlAuth()
    }, [navigate, setConfig, setActiveUser, bool])


    return (auth ? <Outlet />  : <Home />)
}

export default PrivateRoute;
