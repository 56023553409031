import React, { useEffect, useState } from "react";
import configData from "../../../config.json";
import { FaInfoCircle } from "react-icons/fa";
import { questionTypes } from "../../utilities/data/data";
import MCQForm from "./MCQForm";

const Step3ManualForm = ({ questions, onNext, onPrev }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const isValidJSON = (str) => {
    try {
      JSON.parse(str);
      return true;
    } catch (e) {
      return false;
    }
  };
  const handlePrevStep = () => {
    setCurrentStep(1);
  };

  const [questionCategory, setQuestionCategory] = useState(questionTypes[0]);
  const [hoveredStyle, setHoveredStyle] = useState(null);

  const handlequestionCategoryChange = (event) => {
      setQuestionCategory(event.target.value);
    }

  const countTypes = (array) => {
    return array?.reduce((acc, obj) => {
      if (
        (obj?.type === questionTypes[2].label ||
          obj?.type === questionTypes[3].label ||
          obj?.type === questionTypes[7].label) &&
        (!obj?.question || obj?.question.trim() === "")
      ) {
        return acc;
      }
      // Skip counting if the options array is empty or all options are empty strings
      else if (
        (obj?.type === questionTypes[1].label ||
          obj?.type === questionTypes[5].label ||
          obj?.type === questionTypes[6].label) &&
        (obj.options.length === 0 ||
          obj.options.every((option) => option.trim() === ""))
      ) {
        return acc;
      } else if (
        (obj?.type === questionTypes[0].label ||
          obj?.type === questionTypes[4].label ||
          obj?.type === questionTypes[8].label) &&
        (obj.options.length === 0 ||
          obj.options.every((option) => option.trim() === "") ||
          obj?.question.trim() === "")
      ) {
        return acc;
      }

      const type = obj?.type; // Assuming 'type' is always defined
      acc[type] = (acc[type] || 0) + 1;
      return acc;
    }, {});
  };

  const [typeCounts, setTypeCounts] = useState({});
  useEffect(() => {
    setTypeCounts(countTypes(questions || [{}]));
  }, [questions]);

  return (
    <>
      <div className="form-step" style={{marginBottom: '20%'}}>
        {currentStep === 1 && (
          <>
            <h2>Add Questions Manually</h2>
            <label>
              What type of questions will you like to add?{" "}
              <span style={{ color: "red" }}> *</span>
            </label>
            <div className="styled-select-container">
              <select
                className="styled-select"
                value={questionCategory}
                onChange={handlequestionCategoryChange}
              >
                {questionTypes.map((questionType) => (
                  <option
                    key={questionType.value}
                    value={JSON?.stringify(questionType)}
                  >
                    {questionType.label}
                  </option>
                ))}
              </select>
              <FaInfoCircle
                className="info-icon"
                style={{ color: configData.AppColor3 }}
                onMouseEnter={() => {
                  if (isValidJSON(questionCategory)) {
                    setHoveredStyle(JSON.parse(questionCategory));
                  } else {
                    setHoveredStyle({
                      value: questionTypes[0].value,
                      label: questionTypes[0].label,
                      description: questionTypes[0].description,
                    });
                  }
                }}
                onMouseLeave={() => setHoveredStyle(null)}
              />
              {hoveredStyle ? (
                <div className="audio-tooltip" style={{ top: "80%", right: 0 }}>
                  {hoveredStyle?.description}
                </div>
              ) : (
                ""
              )}
            </div>
            {questions?.length > 0 && (
              <div>
                {Object.entries(typeCounts)?.map(([type, count]) => (
                  <p>{`${type}: ${count}`}</p>
                ))}
              </div>
            )}
          </>
        )}
        {currentStep === 2 && (
          <MCQForm
            type={
              isValidJSON(questionCategory)
                ? JSON.parse(questionCategory).label
                : questionTypes[0].label
            }
            questionTypes={questionTypes}
          />
        )}
        <div className="button-wrapper">
          <button
            onClick={() => {
              if (currentStep > 1) {
                handlePrevStep();
              } else {
                onPrev();
              }
            }}
            className="navigation-button"
            style={{ background: configData.AppColor3 }}
          >
            Back
          </button>
          <button
            style={{
              background: configData.AppColor3,
            }}
            className="navigation-button"
            onClick={() => {
              if (currentStep > 1) {
                onNext();
              } else {
                setCurrentStep(2);
              }
            }}
          >
            {currentStep > 1 ? "Create Exam" : "Next"}
          </button>
        </div>
      </div>
    </>
  );
};

export default Step3ManualForm;
