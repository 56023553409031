import React from "react";
import configData from "../../../config.json";
// import { FaInfoCircle } from "react-icons/fa";

const Step2InstructorInformation = ({ data, onInputChange, onPrev, onNext }) => {
  return (
    <div className="form-step" style={{ marginBottom: "25%" }}>
      <h2>Instructor Information</h2>

      <label>
        Instructor Name <span style={{ color: "red" }}> *</span>
      </label>
      <input
        type="text"
        name="instructorName"
        value={data.instructorName}
        onChange={(e) => onInputChange(e)}
        data-step="instructorInformation"
        className="styled-input"
      />

      <label>
        Instructor Rating <span style={{ color: "red" }}> *</span>
      </label>
      <div className="styled-select-container">
        <select
          name="instructorRating"
          value={data.instructorRating}
          onChange={(e) => onInputChange(e)}
          data-step="instructorInformation"
          className="styled-select"
        >
          <option value="">Select Rating</option>
          <option value="1">1 - Poor</option>
          <option value="2">2 - Fair</option>
          <option value="3">3 - Good</option>
          <option value="4">4 - Very Good</option>
          <option value="5">5 - Excellent</option>
        </select>
      </div>

      <div className="button-wrapper">
        <button
          style={{ background: configData.AppColor3 }}
          className="navigation-button"
          onClick={onPrev}
        >
          Back
        </button>
        <button
          style={{ background: configData.AppColor3 }}
          className="navigation-button"
          onClick={onNext}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Step2InstructorInformation;
