import React from "react";
import configData from "../../../config.json";
// import { FaInfoCircle } from "react-icons/fa";

const Step1CourseInformation = ({ data, onInputChange, onNext }) => {
  return (
    <div className="form-step" style={{ marginBottom: "25%" }}>
      <h2>Course Information</h2>

      <label>
        Course Name <span style={{ color: "red" }}> *</span>
      </label>
      <input
        type="text"
        name="courseName"
        value={data.courseName}
        onChange={(e) => onInputChange(e)}
        data-step="courseInformation"
        className="styled-input"
      />

      <label>
        Course Code <span style={{ color: "red" }}> *</span>
      </label>
      <input
        type="text"
        name="courseCode"
        value={data.courseCode}
        onChange={(e) => onInputChange(e)}
        data-step="courseInformation"
        className="styled-input"
      />

      <label>
        Semester <span style={{ color: "red" }}> *</span>
      </label>
      <div className="styled-select-container">
        <select
          name="semester"
          value={data.semester}
          onChange={(e) => onInputChange(e)}
          data-step="courseInformation"
          className="styled-select"
        >
          <option value="">Select Semester</option>
          <option value="Fall">Fall</option>
          <option value="Spring">Spring</option>
          <option value="Summer">Summer</option>
        </select>
      </div>

      <label>
        Year <span style={{ color: "red" }}> *</span>
      </label>
      <input
        type="text"
        name="year"
        value={data.year}
        onChange={(e) => onInputChange(e)}
        data-step="courseInformation"
        className="styled-input"
      />

      <div className="button-wrapper">
        <button
          style={{ background: configData.AppColor3 }}
          className="navigation-button"
          onClick={onNext}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Step1CourseInformation;
