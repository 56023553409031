const styles = [
  {
    value: "none",
    label: "None",
    description:
      "No specific style is applied, allowing for a neutral and flexible approach to the content.",
  },
  {
    value: "narrative",
    label: "Narrative",
    description:
      "Uses storytelling techniques to explain concepts, often employing characters, plot, and setting to engage the audience and provide context to the information being conveyed.",
  },
  {
    value: "expository",
    label: "Expository",
    description:
      "Presents information in a straightforward and logical manner, focusing on clear, factual explanations of concepts, processes, and ideas without the inclusion of personal opinions.",
  },
  {
    value: "descriptive",
    label: "Descriptive",
    description:
      "Provides vivid and detailed descriptions to illustrate and explain ideas, often using sensory details to paint a clear picture for the audience, helping them visualize and understand the subject matter.",
  },
  {
    value: "analytical",
    label: "Analytical",
    description:
      "Involves a detailed examination and breakdown of concepts into their component parts, often using data, evidence, and logical reasoning to understand and explain complex ideas.",
  },
  {
    value: "persuasive",
    label: "Persuasive",
    description:
      "Aims to convince the audience to adopt a particular viewpoint or take a specific action, using arguments, evidence, and emotional appeals to influence their thoughts and behaviors.",
  },
  {
    value: "explanatory",
    label: "Explanatory",
    description:
      "Focuses on providing comprehensive explanations of processes, concepts, or phenomena, often answering the 'how' and 'why' questions to help the audience gain a deeper understanding.",
  },
  {
    value: "dialogic",
    label: "Dialogic/Conversational",
    description:
      "Uses a conversational tone and style to engage the audience, often posing questions and encouraging interaction to create a more dynamic and engaging learning experience.",
  },
  {
    value: "comparative",
    label: "Comparative",
    description:
      "Examines the similarities and differences between two or more ideas, concepts, or phenomena, providing a balanced analysis that helps the audience understand the relative merits and drawbacks of each.",
  },
  {
    value: "reflective",
    label: "Reflective",
    description:
      "Involves personal reflections and insights, encouraging the audience to think deeply about their own experiences and perspectives in relation to the content, fostering a deeper personal connection with the material.",
  },
  {
    value: "summarizing",
    label: "Summarizing",
    description:
      "Condenses information into concise summaries, highlighting the key points and main ideas to provide a quick and clear overview of the content, useful for review and retention.",
  },
  {
    value: "interactive",
    label: "Interactive",
    description:
      "Engages the audience through questions, activities, and participatory elements, encouraging active involvement and making the learning experience more engaging and memorable.",
  },
];

const questionTypes = [
  {
    value: "multiple-choice",
    label: "Multiple Choice Questions",
    description: "Questions with several answer options, only one is correct.",
    note: ["Select the X icon to indicate the correct answer."],
  },
  {
    value: "true-false",
    label: "True or False",
    description: "Respondents decide if statements are true or false.",
    note: [
      "Select the X icon to indicate the correct answer.",
      "Click on the AddQuestion button to append a new true or false question following the current statement.",
    ],
  },
  {
    value: "short-answer",
    label: "Short Answer",
    description: "Respondents give a brief response, usually one or two sentences.",
    note: [`Please provide a detailed explanation for each question to facilitate the formulation of a comprehensive marking scheme.`]
  },
  {
    value: "essay",
    label: "Essay",
    description: "Respondents write a detailed response with critical thinking.",
    note: [`Please provide a detailed explanation for each question to facilitate the formulation of a comprehensive marking scheme.`]
  },
  {
    value: "fill-blanks",
    label: "Fill in the Blanks",
    description: "Respondents fill in missing words in a sentence or paragraph.",
  },
  {
    value: "matching",
    label: "Matching",
    description: "Pair items from two columns, like terms and definitions.",
    note:[ `
        Place the matching pairs sequentially, one below the other, and we will take care of the randomization process for you.
      `],
  },
  {
    value: "ordering",
    label: "Ordering",
    description: "Arrange items in a specific order, like steps or events.",
    note: [
        "Please list the items sequentially; we will take care of shuffling them for you.",
        "Utilize this tool to collaboratively determine the sequence of cycles or the progression of events."
      ],
  },
  {
    value: "rating-scale",
    label: "Rating Scale (Likert Scale)",
    description: "Rate agreement or satisfaction on a scale.",
    note: [
        "Add the question, the purpose of the questions is to ask respondents to rate their agreement or satisfaction on a scale",
        "They are no right or wrong answers, typically used for gauging attitudes, opinions, and perceptions.",
        "We will add the rating options for you, ranging from strongly agree to strongly disagree"
        ],
  },
  {
    value: "numerical-response",
    label: "Numerical Response",
    description: "Provide a numerical answer, often involving calculations.",
    note: ["Options are usually numbers"],
  },
];


module.exports = { styles, questionTypes };
