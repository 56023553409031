import React from "react";

const Timeline = ({ compQuestion, currentQuestionIndex, handleJumpToQuestion }) => {
  return (
    <div className="timeline">
      {compQuestion.map((_, index) => (
        <div
          key={index}
          className={`timeline-item ${index === currentQuestionIndex ? "active" : ""}`}
          onClick={() => handleJumpToQuestion(index)}
        >
          {index + 1}
        </div>
      ))}
    </div>
  );
};

export default Timeline;
