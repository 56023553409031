import React, { useEffect, useState } from "react";
import {
  FaPlusCircle,
  FaMinusCircle,
  FaCheckCircle,
  FaTimesCircle,
  FaTrash,
} from "react-icons/fa";
import { Tooltip } from "react-tooltip";
import RichTextEditor from "../../StoryScreens/editor2";

const QuestionForm = ({
  type,
  currentQuestionIndex,
  compQuestion,
  updateQuestionField,
  handleOptionSelection,
  handleOptionChange,
  addOption,
  deleteOption,
  configData,
  questionTypes,
}) => {
  const [showExplanation, setShowExplanation] = useState(false);
  const [showQuestion, setShowQuestion] = useState(false);
  const [questionType, setQuestionType] = useState({})
  useEffect(() => {
    const questionTypeObj = questionTypes?.filter(questionType => questionType.label === type)
    setQuestionType(questionTypeObj)
  }, [type, questionTypes])
  return (
    <div className="question-form">
      <div
        className="explanation-label-wrapper"
        style={{ marginBottom: "5px" }}
        onClick={() => setShowQuestion(!showQuestion)}
      >
        <label>
          {type === questionTypes[1].label
            ? `Statement - ${currentQuestionIndex + 1}`
            : type === (questionTypes[6].label || questionTypes[5].label)
            ? `Instruction - ${currentQuestionIndex + 1}`
            :`Question - ${currentQuestionIndex + 1}`}
        </label>
        {!showQuestion ? (
          <FaPlusCircle
            style={{
              color: "#555",
              marginTop: "3.5px",
              fontSize: "14px",
            }}
          />
        ) : (
          <FaMinusCircle
            style={{
              color: "#555",
              marginTop: "3.5px",
              fontSize: "14px",
            }}
          />
        )}
      </div>
      {showQuestion && (
        <RichTextEditor
          setData={(value) =>
            updateQuestionField(currentQuestionIndex, "question", value)
          }
          content={compQuestion[currentQuestionIndex].question}
          currentQuestionIndex={currentQuestionIndex}
          placeholder={`Type your ${
            type === questionTypes[1].label ? `statement` : `question`
          } here...`}
          style={{
            maxWidth: "500px",
            height: "250px",
            marginBottom: "80px",
            top1: "17%",
            top2: "11%",
          }}
          inputColor={configData.AppColor3}
          fileId={"file-question"}
        />
      )}
      {!(type === questionTypes[2].label ||
        type === questionTypes[3].label ||
        type === questionTypes[7].label ) && (
        <div className="options">
          {compQuestion[currentQuestionIndex].options.length < 8 && (
            <button
              className="add-option"
              onClick={() => addOption(currentQuestionIndex)}
              style={{ background: configData.AppColor3 }}
            >
              <FaPlusCircle
                data-tooltip-id="add-option-tooltip"
                data-tooltip-content="Add Option"
                className="icon add-question"
                style={{
                  color: "#fff",
                  margin: "0px",
                  marginRight: "5px",
                  fontSize: "18px",
                }}
              />
              {type === questionTypes[1].label
                ? "Add Question"
                : type === questionTypes[8].label
                ? "Add Number"
                : type === (questionTypes[6].label || questionTypes[5].label)
                ? `Add Item`
                : "Add Option"}
            </button>
          )}
          {compQuestion[currentQuestionIndex].options.map((option, oIndex) => (
            <div key={oIndex} className="option">
              <div style={{ position: "relative" }} className="option-wrapper">
                <input
                  type="text"
                  value={option}
                  onChange={(e) =>
                    handleOptionChange(
                      currentQuestionIndex,
                      oIndex,
                      e.target.value
                    )
                  }
                  placeholder={
                    type === questionTypes[1].label
                      ? `Question ${oIndex + 1}`
                      : type === (questionTypes[6].label || questionTypes[5].label)
                      ? `Item ${oIndex + 1}`
                      : `Option ${oIndex + 1}`
                  }
                />
                {type !== (questionTypes[5].label || questionTypes[6].label) && <div
                  style={{
                    position: "absolute",
                    right: "4%",
                    top: "5px",
                    cursor: "pointer",
                  }}
                >
                  {type === questionTypes[1].label ? (
                    compQuestion[currentQuestionIndex].correctOption.includes(
                      oIndex
                    ) ? (
                      <FaCheckCircle
                        style={{ fontSize: "20px", color: "green" }}
                        onClick={() =>
                          handleOptionSelection(currentQuestionIndex, oIndex)
                        }
                      />
                    ) : (
                      <FaTimesCircle
                        style={{ fontSize: "20px", color: "red" }}
                        onClick={() =>
                          handleOptionSelection(currentQuestionIndex, oIndex)
                        }
                      />
                    )
                  ) : oIndex ===
                    compQuestion[currentQuestionIndex].correctOption ? (
                    <FaCheckCircle
                      style={{ fontSize: "20px", color: "green" }}
                      onClick={() =>
                        handleOptionSelection(currentQuestionIndex, -1)
                      }
                    />
                  ) : (
                    <FaTimesCircle
                      style={{ fontSize: "20px", color: "red" }}
                      onClick={() =>
                        handleOptionSelection(currentQuestionIndex, oIndex)
                      }
                    />
                  )}
                </div>}
              </div>
              {compQuestion[currentQuestionIndex].options.length > 1 && (
                <FaTrash
                  data-tooltip-id="delete-option-tooltip"
                  data-tooltip-content="Delete Option"
                  className="icon delete-question"
                  onClick={() => deleteOption(currentQuestionIndex, oIndex)}
                />
              )}
              <Tooltip id="delete-option-tooltip" place="top" effect="solid" />
            </div>
          ))}
        </div>
      )}
      {questionType.length > 0 && questionType[0].note && <div style={{alignSelf: 'flex-start', marginTop: '5px', display: 'flex', gap: '3px'}}>
        <p style={{fontWeight: 'bold'}}>Note<span style={{color: 'red'}}>*</span><span>:</span></p>
        <div>
          {questionType[0]?.note?.map((note, i) => (
            <p>{questionType[0].note.length > 1 ? `${i + 1}.` : ''} {note}</p>
          ))
          }
        </div>
      </div>}
      <div
        className="explanation-label-wrapper"
        onClick={() => setShowExplanation(!showExplanation)}
      >
        <label>
          {type === questionTypes[7].label ? "Comment" : "Explanation"}
        </label>
        {!showExplanation ? (
          <FaPlusCircle
            style={{
              color: "#555",
              marginTop: "5px",
              fontSize: "14px",
            }}
          />
        ) : (
          <FaMinusCircle
            style={{
              color: "#555",
              marginTop: "5px",
              fontSize: "14px",
            }}
          />
        )}
      </div>
      {showExplanation && (
        <RichTextEditor
          setData={(value) =>
            updateQuestionField(currentQuestionIndex, "explanation", value)
          }
          content={compQuestion[currentQuestionIndex].explanation}
          currentQuestionIndex={currentQuestionIndex}
          placeholder={"Write a comprehensive explanation for the question..."}
          style={{
            maxWidth: "500px",
            height: "250px",
            marginTop: "20px",
            top1: "26%",
            top2: "20%",
          }}
          inputColor={configData.AppColor3}
          fileId={"file-explanation"}
        />
      )}
    </div>
  );
};

export default QuestionForm;
