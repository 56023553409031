import React from "react";
import configData from "../../../config.json";
import { FaFolderOpen } from 'react-icons/fa'

const Step4ResourceSharing = ({ data, onInputChange, onFileChange, onPrev, onNext }) => {
  return (
    <div className="form-step" style={{ marginBottom: "25%" }}>
      <h2>Resource Sharing</h2>

      <label>
        Recommended Resources <span style={{ color: "red" }}> *</span>
      </label>
      <textarea
        name="recommendedResources"
        value={data.recommendedResources}
        onChange={(e) => onInputChange(e)}
        data-step="resourceSharing"
        className="styled-input"
      ></textarea>

      <label>
        Study Materials <span style={{ color: "red" }}> *</span>
      </label>
      <div className="file-upload">
        <input
          type="file"
          id="studyMaterials"
          onChange={(e) => onFileChange(e)}
          style={{ display: "none" }}
          multiple
        />
        <label
          htmlFor="studyMaterials"
          className="file-upload-label"
          style={{ background: configData.AppColor3, color: "#fff" }}
        >
          <FaFolderOpen style={{ fontSize: "20px" }} /> Choose Files
        </label>
      </div>

      <div className="button-wrapper">
        <button
          style={{ background: configData.AppColor3 }}
          className="navigation-button"
          onClick={onPrev}
        >
          Back
        </button>
        <button
          style={{ background: configData.AppColor3 }}
          className="navigation-button"
          onClick={onNext}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Step4ResourceSharing;
