import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// import SearchForm from "../GeneralScreens/searchFormSearch";
import instance from "../../Context/axiosConfig";
import Loader from "../GeneralScreens/Loader";
import Pagination from "../GeneralScreens/Pagination";
import { Helmet } from "react-helmet";
import configData from "../../config.json";
import "../../Css/allExams.css";

const AllAudios = ({isOpen}) => {
  const navigate = useNavigate();
  const [audios, setAudios] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
   // eslint-disable-next-line
  const [searchTerm, setSearchTerm] = useState("");
  useEffect(() => {
    const fetchAudios = async () => {
      try {
        setLoading(true);
        const response = await instance.get(
          `/audio/audio-collections?page=${page}&search=${searchTerm}`
        );
        const { collections, totalPages } = response.data;
        setAudios(collections);
        setPages(totalPages);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchAudios();
  }, [page, searchTerm]);

  const handleNavigation = (audioId, audio) => {
    navigate(`/audio/audio-detail/${audioId}/${audio.title}`);
    localStorage.setItem('audio-imageUrl', audio.imageUrl)
    localStorage.setItem('audio-creator', audio.createdBy)
    localStorage.setItem('audio-description', audio.description)
  };

  return (
    <>
      <Helmet>
        <title>{`Audio Hub | ${configData.Name}`}</title>
        <meta
          name="description"
          content={`Audio Hub comprises a list of audios prepared to simulate a specific question pattern or exam. 
                    Here, audios are already structured based on a topic or standard exam. 
                    Listening to audios organized by professionals is a good way to intensify your understanding of a topic or grasp the patterns of exams.`}
        />
      </Helmet>
      <div className={`all-exams-wrapper`}>
        <div className={`all-exam-cards-wrapper`}>
          {loading ? (
            <Loader />
          ) : (
            audios.map((audio) => (
              <div
                className="exam-card-wrapper"
                key={audio._id}
                onClick={() => handleNavigation(audio._id, audio)}
              >
                <div style={{ width: "30%", padding: "20px" }}>
                  <img
                    src={audio.imageUrl}
                    alt="Audio"
                    className="exam-card-image"
                  />
                </div>
                <div>
                  <h1 className="exam-card-details">
                    {audio.title.length > 30
                      ? `${audio.title.trim().slice(0, 30)}..`
                      : audio.title}
                  </h1>
                  <p className="exam-card-details">
                    Description:{" "}
                    <strong>
                      {audio.description.length > 35
                        ? `${audio.description.slice(0, 35)}...`
                        : audio.description}
                    </strong>
                  </p>
                  <p className="exam-card-date">
                    Date: {new Date(audio.date).toLocaleDateString()}
                  </p>
                </div>
              </div>
            ))
          )}
          <Pagination page={page} pages={pages} changePage={setPage} />
        </div>
      </div>
    </>
  );
};

export default AllAudios;
