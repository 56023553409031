// AudioCollectionDetails.js
import React, { useState, useEffect, useContext, useCallback } from "react";
import { useParams } from "react-router-dom";
import instance from "../../Context/axiosConfig";
import { AuthContext } from "../../Context/AuthContext";
import uuid from "react-uuid"; // Importing uuid package
import "../../Css/audioDetails.css";
// import Loader from "../GeneralScreens/Loader";
import CustomAudioPlayer from "./audioWrapper";
import { FaRegClock, FaEllipsisV } from "react-icons/fa";
import formatTimeAgo from "../utilities/formatDate";
import getAudioDuration from "../utilities/getAudioDuration";
import {
  transformDropboxUrl,
  formatDuration,
  playAudio,
  reStartAudio,
} from "../utilities/audiohelpers";
import { useMediaQuery } from "react-responsive";
import configData from "../../config.json";
import PlaylistBanner from "./playlistBanner";

const AudioCollectionDetails = ({ isOpen }) => {
  ///////////////////////////////////////////////////////////// parameter variables

  const { audioCollectionId, audioCollectionTitle } = useParams();
  const { config } = useContext(AuthContext);

  // state variables
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState("");
  const [isAuthorized, setIsAuthorized] = useState(true);
  //direct audio state variables
  const [audios, setAudios] = useState([]);
  const [audio, setAudio] = useState({});
  const [audioLength, setAudioLength] = useState(0);
  const [audioDurations, setAudioDurations] = useState({});
  const [currentAudioIndex, setCurrentAudioIndex] = useState();
  const [isPlaying, setIsPlaying] = useState(false)

  //responsive design
  const isMobile = useMediaQuery({ maxWidth: "600px" });

  //localstorage
  const audioCollectionImage = localStorage.getItem("audio-imageUrl");
  // const audioCreator = localStorage.getItem("audio-creator");
  const audioDescription = localStorage.getItem("audio-description");
  const [deviceInfo, setDeviceInfo] = useState(
    localStorage.getItem("deviceInfo")
  );
  /////////////////////////////////////////////////////////////////

  //////////////////////////////////////////////////////////////// useEffect hooks
  //for audio control
  useEffect(() => {
    if (audio && currentAudioIndex !== null) {
      playAudio(audio._id);
    }
  }, [audio, currentAudioIndex]);

  //to ensure only one audio plays at a time
  useEffect(() => {
    // Check if all audios have been played
    if (currentAudioIndex >= audios?.length) {
      setCurrentAudioIndex(0);
    }
    const otherAudios = audios.filter(
      (audio, index) => index !== currentAudioIndex
    );
    otherAudios.forEach((audio) => {
      const audioPlayer = document.getElementById(`audio-${audio?._id}`);
      if (audioPlayer) {
        audioPlayer.pause();
      }
    });
  }, [audios, currentAudioIndex]);

  const updateCurrentAudioIndex = (index) => {
    setCurrentAudioIndex(index);
  };

  //ensures the dropbox url is always playable
  useEffect(() => {
    audios?.forEach((audio) => {
      getAudioDuration(transformDropboxUrl(audio.audioUrl), (duration) => {
        setAudioDurations((prevDurations) => ({
          ...prevDurations,
          [audio._id]: duration,
        }));
      });
    });
  }, [audios]);
  ///////////////////////////////////////////////////////////////////

  //////////////////////////////////////////function that gets the audios from the server
  const fetchAudioCollection = useCallback(async () => {
    try {
      setLoading(true);
      const response = await instance.get(
        `/audio/audiosById?collectionId=${audioCollectionId}&deviceInfo=${deviceInfo}`,
        config
      );
      setAudios(response.data);
      setLoading(false);
      setAudioLength(response.data.length);
      setTimeout(() => {
        setSuccess("");
      }, 4000);
      setIsAuthorized(true); // Assume the user is authorized initially
    } catch (error) {
      console.error("Error fetching audio collection:", error);
      setError(error?.response?.data?.message);
      setTimeout(() => {
        setError("");
      }, 4000);
      setIsAuthorized(false);
      setLoading(false);
      if (error.response.data.statusCode === 500) {
        setIsAuthorized(true);
        setError("something went wrong, please refresh the page");
      }
    }
  }, [audioCollectionId, config, deviceInfo]);

  ////////////////////effect to get device info
  useEffect(() => {
    let storedDeviceInfo = localStorage.getItem("deviceInfo");
    if (!storedDeviceInfo) {
      storedDeviceInfo = uuid();
      localStorage.setItem("deviceInfo", storedDeviceInfo);
    }
    setDeviceInfo(storedDeviceInfo);

    fetchAudioCollection();
  }, [audioCollectionId, fetchAudioCollection]);

  const handleEnded = () => {
    setCurrentAudioIndex((prevIndex) => prevIndex + 1);
    const audioPlayer = document.getElementById(
      `audio-${audios[currentAudioIndex + 1]?._id}`
    );
    if (audioPlayer) {
      audioPlayer.play();
    }
  };

  const handleAuthorize = async () => {
    try {
      setLoading(true);
      await instance.post(
        "/audio/authorize",
        { token, deviceInfo, collectionId: audioCollectionId },
        config
      );
      setLoading(false);
      setIsAuthorized(true);
      setSuccess("collection has been unlocked successfully✔✔");
      setTimeout(() => {
        setSuccess("");
      }, 4000);
      fetchAudioCollection();
    } catch (error) {
      setLoading(false);
      setError(error.response.data.message);
      setTimeout(() => {
        setError("");
      }, 4000);
      console.log(error);
    }
  };

  return (
    <div className={`audio-collection-details`}>
      {!isAuthorized && (
        <>
          <h1 className="caption">{audioCollectionTitle}</h1>
          <div className="authorization-form">
            <label htmlFor="tokenInput">Enter Token:</label>
            <input
              type="text"
              id="tokenInput"
              value={token}
              onChange={(e) => setToken(e.target.value)}
            />
            <button onClick={handleAuthorize}>Authorize</button>
          </div>
        </>
      )}
      {/* {loading && <Loader />} */}
      {error && <p style={{ color: "red" }}>{error}</p>}
      {success && <p style={{ color: "green" }}>{success}</p>}
      <div className="audio-list">
        {isAuthorized && (
          <>
            <PlaylistBanner
              playlist={{
                type: "Collection",
                image: audioCollectionImage,
                title: audioCollectionTitle,
                creator: "Kingsheart",
                description: audioDescription,
                tracks: audioLength,
                duration: Object.values(audioDurations).reduce(
                  (acc, duration) => acc + duration,
                  0
                )
              }}
              setAudio={setAudio}
              audios= {audios}
              audio= {audio}
              currentAudioIndex= {currentAudioIndex}
              isPlaying = {isPlaying}
              setIsPlaying = {setIsPlaying}
              setCurrentAudioIndex={setCurrentAudioIndex}
              loading = {loading}
            />
            <table className="audio-table">
              <thead>
                <tr>
                  <th>
                    <span style={{ fontSize: "18px" }}>#</span> Title
                  </th>
                  {!isMobile && <th>Voice actor</th>}
                  {!isMobile && <th>Date created</th>}
                  {!isMobile && (
                    <th style={{ margin: "0 auto" }}>
                      <FaRegClock />
                    </th>
                  )}
                </tr>
              </thead>
              <tbody>
                {audios?.map((audio, index) => (
                  <tr
                    key={audio?._id}
                    onClick={() => {
                      if (index === currentAudioIndex) {
                        reStartAudio(audio?._id);
                        return;
                      }
                      setCurrentAudioIndex(index);
                      setAudio(audio);
                      setIsPlaying(true)
                    }}
                  >
                    <td>
                      <div className="audio-title-icon-wrapper">
                        <div className="audio-title-wrapper">
                          <div className="image-index-wrapper">
                            <img
                              src={audioCollectionImage}
                              alt="audioimage"
                              className="audio-image"
                            />
                            <div className="audio-index">{index + 1}</div>
                          </div>
                          <div className="audio-caption-wrapper">
                            <h3
                              style={{
                                fontSize: "18px",
                                marginTop: "3px",
                                color:
                                  currentAudioIndex === index
                                    ? configData.AppColor
                                    : "#333",
                              }}
                            >
                              {audio?.title?.length <= 25
                                ? audio?.title
                                : audio?.title?.slice(0, 25) + "..."}
                            </h3>
                            <h3 style={{ fontSize: "15px" }}>
                              {audio?.section
                                ? audio?.section?.length <= 25
                                  ? audio?.section
                                  : audio?.section?.slice(0, 25) + "..."
                                : "Not specified"}
                            </h3>
                          </div>
                        </div>
                        {isMobile ? <FaEllipsisV /> : ""}
                      </div>
                    </td>
                    {!isMobile && <td>{audio?.voice || "Not specified"}</td>}
                    {!isMobile && (
                      <td>
                        {formatTimeAgo(new Date(audio?.date)) ||
                          "Not specified"}
                      </td>
                    )}
                    {!isMobile && (
                      <td>
                        {audioDurations[audio._id]
                          ? formatDuration(audioDurations[audio._id])
                          : "calculating"}
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        )}
        {isAuthorized && audio?.audioUrl && (
          <CustomAudioPlayer
            src={transformDropboxUrl(audio.audioUrl)}
            controls
            className="audio-player"
            controlsList="nodownload"
            id={`audio-${audio?._id}`}
            currentAudioIndex={currentAudioIndex}
            onEnded={handleEnded}
            updateCurrentAudioIndex={() =>
              updateCurrentAudioIndex(currentAudioIndex)
            }
            setIsPlaying = {setIsPlaying}
            isPlaying= {isPlaying}
            audioCollectionImage={audioCollectionImage}
          />
        )}
      </div>
    </div>
  );
};

export default AudioCollectionDetails;
