// CustomAudioPlayer.js
import React, { Component } from "react";
import PropTypes from "prop-types";
import "../../Css/customAudioPlayer.css"; // Import CSS for custom styling
import {
  FaTachometerAlt,
  FaHeart,
  FaForward,
  FaPlay,
  FaPause,
  FaBackward,
  FaEllipsisV,
} from "react-icons/fa";
import { MdReplay10, MdForward10 } from "react-icons/md";
import configData from "../../config.json";

class CustomAudioPlayer extends Component {
  static propTypes;
  static defaultProps;

  constructor(props) {
    super(props);
    this.audioRef = React.createRef();
    this.state = {
      currentTime: 0,
      duration: 0,
      isPlaying: false,
      showCardOptions: false,
      showSpeedControl: false,
      playbackSpeed: 1.0,

    };
  }
  pauseAudio = () => {
    const audio = this.audioRef.current;
    if (audio) {
      this.props.setIsPlaying(false);
      this.setState({ isPlaying: false });
    }
  };

  handleAudioEnded = () => {
    this.setState({ isPlaying: false, currentTime: 0 }); // Reset isPlaying and currentTime
    this.props.setIsPlaying(false);
  };
  handlePlaying = () => {
    this.setState({ isPlaying: true });
    this.props.setIsPlaying(true);
  };
  componentDidMount() {
    const audio = this.audioRef.current;
    if (!audio) return;

    this.updateVolume(this.props.volume);

    audio.addEventListener("timeupdate", this.onTimeUpdate);
    audio.addEventListener("loadedmetadata", this.onLoadedMetadata);
    audio.addEventListener("ended", this.handleAudioEnded); // Add event listener for 'ended'
    audio.addEventListener("playing", this.handlePlaying); // Add event listener for 'playing'
  }

  componentWillUnmount() {
    const audio = this.audioRef.current;

    if (!audio) return;

    audio.removeEventListener("timeupdate", this.onTimeUpdate);
    audio.removeEventListener("loadedmetadata", this.onLoadedMetadata);
  }

  onLoadedMetadata = () => {
    const audio = this.audioRef.current;
    if (audio) {
      this.setState({ duration: audio.duration });
    }
  };

  onTimeUpdate = () => {
    const audio = this.audioRef.current;
    if (audio) {
      this.setState({ currentTime: audio.currentTime });
    }
  };

  handlePlayPause = () => {
    const audio = this.audioRef.current;
    const { index, updateCurrentAudioIndex } = this.props;
    updateCurrentAudioIndex(index);
    if (audio) {
      // if (audio.readyState >= 2) {
      if (audio.paused) {
        audio.play();
        this.setState({ isPlaying: true });
        this.props.setIsPlaying(true);
      } else {
        audio.pause();
        this.setState({ isPlaying: false });
        this.props.setIsPlaying(false);
      }
    }
  };
  componentDidUpdate(prevProps) {
    if (prevProps.isPlaying !== this.props.isPlaying) {
      this.setState({ isPlaying: this.props.isPlaying });
    }
  }

  handleSeek = (seekTime) => {
    const audio = this.audioRef.current;
    if (audio) {
      const newTime = audio.currentTime + seekTime;
      audio.currentTime = Math.max(0, Math.min(audio.duration, newTime));
      this.setState({ currentTime: audio.currentTime });
    }
  };

  toggleSpeedControl = () => {
    this.setState((prevState) => ({
      showSpeedControl: !prevState.showSpeedControl,
    }));
  };
  toggleshowCardOptions = () => {
    this.setState((prevState) => ({
      showCardOptions: !prevState.showCardOptions,
    }));
  };

  handlePlaybackSpeedChange = (speed) => {
    this.setState({ playbackSpeed: speed, showSpeedControl: false });
    localStorage.setItem("playBack", speed);
    const audio = this.audioRef.current;
    if (audio) {
      audio.playbackRate = speed;
    }
  };

  updateVolume(volume) {
    const audio = this.audioRef.current;
    if (
      audio !== null &&
      typeof volume === "number" &&
      volume !== audio?.volume
    ) {
      audio.volume = volume;
    }
  }

  render() {
    const {
      currentTime,
      duration,
      isPlaying,
      showSpeedControl,
      playbackSpeed,
      showCardOptions
    } = this.state;

    return (
      <div className="custom-audio-player">
        <div className="image-index-wrapper">
          <img
            src={this.props.audioCollectionImage}
            alt="audioimage"
            className="audio-image"
            style={{ width: "70px", height: "70px" }}
          />
          <div
            className="audio-index"
            style={{ fontSize: "20px", padding: "1px 5px" }}
          >
            {this.props.currentAudioIndex + 1}
          </div>
        </div>
        <div className="seek-control-container">
          <div className="seek-bar-container">
            <span className="time">
              {`${String(Math.floor(currentTime / 60)).padStart(
                2,
                "0"
              )}:${String(Math.floor(currentTime) % 60).padStart(2, "0")}`}
            </span>

            <input
              style={{ width: "100%", background: configData.AppColor }}
              type="range"
              min={0}
              max={duration || 0}
              value={currentTime}
              onChange={(e) =>
                this.handleSeek(parseFloat(e.target.value) - currentTime)
              }
              className="seek-bar"
            />

            <span className="time">
              {`${String(Math.floor(duration / 60)).padStart(2, "0")}:${String(
                Math.floor(duration) % 60
              ).padStart(2, "0")}`}
            </span>
          </div>
          <div className="controls">
            <audio
              ref={this.audioRef}
              src={this.props.src}
              autoPlay={
                this.audioRef?.current?.readyState >= 2 && this?.props?.autoPlay
              }
              preload={this.props.preload}
              loop={this.props.loop}
              controls={false} // Hide default controls
              onEnded={this.props.onEnded}
              id={this.props.id}
            />
            <div className="handle-seek-wrapper">
              <button
                className="control-button"
                onClick={() =>
                  this.props.updateCurrentAudioIndex(
                    this.props.currentAudioIndex >= 0 &&
                      this.props.currentAudioIndex - 1
                  )
                }
              >
                <FaBackward size={20} />
              </button>
              <button
                className="control-button"
                onClick={() => this.handleSeek(-10)}
              >
                <MdReplay10 size={20} />
              </button>
              <button className="control-button" onClick={this.handlePlayPause}>
                {isPlaying ? <FaPause size={20} /> : <FaPlay size={20} />}
              </button>
              <button
                className="control-button"
                onClick={() => this.handleSeek(10)}
              >
                <MdForward10 size={20} />
              </button>
              <button
                className="control-button"
                onClick={() =>
                  this.props.updateCurrentAudioIndex(
                    this.props.currentAudioIndex + 1
                  )
                }
              >
                <FaForward size={20} />
              </button>
            </div>
            <div className="speed-control-container">
              <FaEllipsisV
                className="control-button"
                onClick={
                  () => {
                  this.toggleshowCardOptions()
                  this.setState({
                    showSpeedControl: false
                  })
                }
                }
                style={{ fontSize: "30px", marginRight: "15px" }}
              />
              {showCardOptions && (
                <>
                  <div className="speed-control-card">
                  <div className="speed-control">
                        <button
                          className= 'speed-button'
                          style={{display: 'flex', gap: '5px', alignItems: 'center'}}
                        >
                          <FaHeart /> Add to favourites
                        </button>
                        <button
                          className= 'speed-button'
                          style={{display: 'flex', gap: '5px', alignItems: 'center'}}
                          onClick={() => {
                            this.toggleSpeedControl()
                            this.toggleshowCardOptions()
                          }
                          }
                        >
                          <FaTachometerAlt /> PlaybackSpeed
                        </button>
                  </div>
                </div>
                </>
              )}
              {showSpeedControl && (
                <div className="speed-control-card">
                  <div className="speed-control">
                    {[0.25, 0.5, 0.75, 1.0, 1.25, 1.5, 1.75, 2.0].map(
                      (speed) => (
                        <button
                          key={speed}
                          className={`speed-button ${
                            playbackSpeed === speed ? "active" : ""
                          }`}
                          onClick={() => this.handlePlaybackSpeedChange(speed)}
                        >
                          {speed}x
                        </button>
                      )
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

CustomAudioPlayer.defaultProps = {
  autoPlay: false,
  loop: false,
  preload: "metadata",
  volume: 1.0,
};

CustomAudioPlayer.propTypes = {
  src: PropTypes.string.isRequired,
  autoPlay: PropTypes.bool,
  loop: PropTypes.bool,
  preload: PropTypes.oneOf(["", "none", "metadata", "auto"]),
  volume: PropTypes.number,
  onended: PropTypes.func,
  currentAudioIndex: PropTypes.number,
  id: PropTypes.string,
  setIsPlaying: PropTypes.func,
  isPlaying: PropTypes.bool,
  audioCollectionImage: PropTypes.string,
};

export default CustomAudioPlayer;
