import React, { useState } from "react";
import configData from "../../config.json";
import formWrapperImage from "../../img/courseExperience.png";
import "../../Css/addQuestion.css";
import FormWrapper from "../questionScreens/add-question/formWrapper";
import Step1CourseInformation from "./experienceSteps/Step1CourseInformation";
import Step2InstructorInformation from "./experienceSteps/Step2InstructorInformation";
import Step3ExperienceDescription from "./experienceSteps/Step3ExperienceDescription";
import Step4ResourceSharing from "./experienceSteps/Step4ResourceSharing";
import Step5EngagementParticipation from "./experienceSteps/Step5EngagementParticipation";
import Step6AssessmentEvaluation from "./experienceSteps/Step6AssessmentEvaluation";
import Step7AdditionalComments from "./experienceSteps/Step7AdditionalComments";
import Step8PrivacyOptions from "./experienceSteps/Step8PrivacyOptions";
import { step1Texts } from "../utilities/data/formText";

const ShareExperienceForm = ({ isOpen }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState({
    courseInformation: {
      courseName: "",
      courseCode: "",
      semester: "",
      year: "",
    },
    instructorInformation: {
      instructorName: "",
      instructorRating: 0,
    },
    experienceDescription: {
      overview: "",
      challenges: "",
      successStrategies: "",
      keyLearnings: "",
      workload: 0,
    },
    resourceSharing: {
      recommendedResources: "",
      studyMaterials: null,
    },
    engagementParticipation: {
      classParticipation: "",
      groupWork: "",
    },
    assessmentEvaluation: {
      examStructure: "",
      gradingCriteria: "",
    },
    additionalComments: {
      generalAdvice: "",
      questions: "",
    },
    privacyOptions: {
      shareAnonymously: false,
    },
  });

  const handleNextStep = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const handlePrevStep = () => {
    setCurrentStep((prevStep) => prevStep - 1);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const step = e.target.dataset.step;
    setFormData((prevData) => ({
      ...prevData,
      [step]: {
        ...prevData[step],
        [name]: value,
      },
    }));
  };
 // eslint-disable-next-line
  const handleRatingChange = (rating, step, name) => {
    setFormData((prevData) => ({
      ...prevData,
      [step]: {
        ...prevData[step],
        [name]: rating,
      },
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormData((prevData) => ({
      ...prevData,
      resourceSharing: {
        ...prevData.resourceSharing,
        studyMaterials: file,
      },
    }));
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      privacyOptions: {
        ...prevData.privacyOptions,
        [name]: checked,
      },
    }));
  };

  return (
    <div className={`multi-step-form ${isOpen ? "open" : "close"}`}>
      {currentStep === 1 && (
        <FormWrapper
          isOpen={isOpen}
          texts={step1Texts}
          configData={configData}
          background={configData.AppColor2}
          formWrapperImage={formWrapperImage}
        >
          <Step1CourseInformation
            data={formData.courseInformation}
            onInputChange={handleInputChange}
            onNext={handleNextStep}
          />
        </FormWrapper>
      )}
      {currentStep === 2 && (
        <FormWrapper
          isOpen={isOpen}
          texts={step1Texts}
          configData={configData}
          background={configData.AppColor2}
          formWrapperImage={formWrapperImage}
        >
          <Step2InstructorInformation
            data={formData.courseInformation}
            onInputChange={handleInputChange}
            onPrev={handlePrevStep}
            onNext={handleNextStep}
          />
        </FormWrapper>
      )}
      {currentStep === 3 && (
        <FormWrapper
          isOpen={isOpen}
          texts={step1Texts}
          configData={configData}
          background={configData.AppColor2}
          formWrapperImage={formWrapperImage}
        >
          <Step3ExperienceDescription
            data={formData.experienceDescription}
            onInputChange={handleInputChange}
            onPrev={handlePrevStep}
            onNext={handleNextStep}
          />
        </FormWrapper>
      )}
      {currentStep === 4 && (
        <FormWrapper
          isOpen={isOpen}
          texts={step1Texts}
          configData={configData}
          background={configData.AppColor2}
          formWrapperImage={formWrapperImage}
        >
          <Step4ResourceSharing
            data={formData.resourceSharing}
            onInputChange={handleInputChange}
            onFileChange={handleFileChange}
            onNext={handleNextStep}
            onPrev={handlePrevStep}
          />
        </FormWrapper>
      )}
      {currentStep === 5 && (
        <FormWrapper
          isOpen={isOpen}
          texts={step1Texts}
          configData={configData}
          background={configData.AppColor2}
          formWrapperImage={formWrapperImage}
        >
          <Step5EngagementParticipation
            data={formData.engagementParticipation}
            onInputChange={handleInputChange}
            onNext={handleNextStep}
            onPrev={handlePrevStep}
          />
        </FormWrapper>
      )}
      {currentStep === 6 && (
        <FormWrapper
          isOpen={isOpen}
          texts={step1Texts}
          configData={configData}
          background={configData.AppColor2}
          formWrapperImage={formWrapperImage}
        >
          <Step6AssessmentEvaluation
            data={formData.assessmentEvaluation}
            onInputChange={handleInputChange}
            onNext={handleNextStep}
            onPrev={handlePrevStep}
          />
        </FormWrapper>
      )}
      {currentStep === 7 && (
        <FormWrapper
          isOpen={isOpen}
          texts={step1Texts}
          configData={configData}
          background={configData.AppColor2}
          formWrapperImage={formWrapperImage}
        >
          <Step7AdditionalComments
            data={formData.additionalComments}
            onInputChange={handleInputChange}
            onNext={handleNextStep}
            onPrev={handlePrevStep}
          />
        </FormWrapper>
      )}
      {currentStep === 8 && (
        <FormWrapper
          isOpen={isOpen}
          texts={step1Texts}
          configData={configData}
          background={configData.AppColor2}
          formWrapperImage={formWrapperImage}
        >
          <Step8PrivacyOptions
            data={formData.privacyOptions}
            onInputChange={handleCheckboxChange}
            onPrev={handlePrevStep}
            onSubmit={() => {
              console.log(formData);
              // Submit form data
            }}
          />
        </FormWrapper>
      )}
    </div>
  );
};

export default ShareExperienceForm;
