import React from "react";
import configData from "../../../config.json";
import '../../../Css/addQuestion.css'

const Step1ChooseMethod = ({ handleSelectMethod }) => {
    return(
        <div className="step1-content" style={{  marginBottom: '200px' }}>
        <h2>How Would You Like To Add Questions?</h2>
        <button
          style={{
            background: configData.AppColor3,
          }}
          className="method-button"
          onClick={() => handleSelectMethod("ordered")}
        >
          Upload Ordered Document
        </button>
        <button
          className="method-button"
          onClick={() => handleSelectMethod("unordered")}
          style={{
            background: configData.AppColor3,
          }}
        >
          Upload Unordered Document
        </button>
        <button
          className="method-button"
          onClick={() => handleSelectMethod("manual")}
          style={{
            background: configData.AppColor3,
          }}
        >
          Add Manually
        </button>
      </div>
    )
}

export default Step1ChooseMethod