import React, { useEffect } from "react";
import "../../Css/playlistBanner.css";
import {
  formatEstimatedDuration,
  playAudio,
  pauseAudio,
} from "../utilities/audiohelpers";
import { FaPlay, FaPause, FaEllipsisV, FaHeart } from "react-icons/fa";
import configData from "../../config.json";

const PlaylistBanner = ({
  playlist,
  currentAudioIndex,
  audio,
  audios,
  setAudio,
  setIsPlaying,
  isPlaying,
  setCurrentAudioIndex,
  loading
}) => {
  //for audio control
  useEffect(() => {
    if (audio && currentAudioIndex !== null) {
      playAudio(audio?._id);
    }
  }, [audio, currentAudioIndex]);
  return (
    <div>
      <div className="playlist-banner-container">
        <div
          className="playlist-banner"
          style={{ backgroundImage: `url(${playlist.image})` }}
        >
          <div className="overlay"></div>
          <div className="playlist-content">
            <img
              src={playlist.image}
              alt="Playlist"
              className="playlist-image"
            />
            <div className="playlist-text-content">
              <h3 className="playlist-type">{playlist.type}</h3>
              <h1 className="playlist-title">{playlist.title}</h1>
              <div className="playlist-info">
                <span>{playlist.creator} </span>
                <span>{playlist.tracks} Materials </span>
                <span style={{ color: "#ccc" }}>
                  {formatEstimatedDuration(playlist.duration)}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="playlist-actions">
        <button
          className="play-button"
          style={{ background: configData.AppColor }}
          onClick={() => {
            if(loading) return;
            if(!currentAudioIndex){
              setCurrentAudioIndex(0)
            }
            if(isPlaying){
              pauseAudio(audio?._id)
              setIsPlaying(false)
              return
            } else{
              playAudio(audio?._id)
              setIsPlaying(true)
            }
            setAudio(audios[currentAudioIndex || 0]);
            setIsPlaying(true)
          }}
        >
          {isPlaying ? <FaPause /> : <FaPlay />}
        </button>
        <button
          className="favorite-button"
          style={{ background: configData.AppColor }}
        >
          <FaHeart />
        </button>
        <button
          className="options-button"
          style={{ background: configData.AppColor }}
        >
          <FaEllipsisV />
        </button>
      </div>
    </div>
  );
};

export default PlaylistBanner;
