import React from "react";
import configData from "../../../config.json";

const Step3ExperienceDescription = ({ data, onInputChange, onPrev, onNext }) => {
  return (
    <div className="form-step" style={{ marginBottom: "25%" }}>
      <h2>Experience Description</h2>

      <label>
        Overview <span style={{ color: "red" }}> *</span>
      </label>
      <textarea
        name="overview"
        value={data.overview}
        onChange={(e) => onInputChange(e)}
        data-step="experienceDescription"
        className="styled-input"
      ></textarea>

      <label>
        Challenges <span style={{ color: "red" }}> *</span>
      </label>
      <textarea
        name="challenges"
        value={data.challenges}
        onChange={(e) => onInputChange(e)}
        data-step="experienceDescription"
        className="styled-input"
      ></textarea>

      <label>
        Success Strategies <span style={{ color: "red" }}> *</span>
      </label>
      <textarea
        name="successStrategies"
        value={data.successStrategies}
        onChange={(e) => onInputChange(e)}
        data-step="experienceDescription"
        className="styled-input"
      ></textarea>

      <label>
        Key Learnings <span style={{ color: "red" }}> *</span>
      </label>
      <textarea
        name="keyLearnings"
        value={data.keyLearnings}
        onChange={(e) => onInputChange(e)}
        data-step="experienceDescription"
        className="styled-input"
      ></textarea>

      <label>
        Workload <span style={{ color: "red" }}> *</span>
      </label>
      <textarea
        name="workload"
        value={data.workload}
        onChange={(e) => onInputChange(e)}
        data-step="experienceDescription"
        className="styled-input"
      ></textarea>

      <div className="button-wrapper">
        <button
          style={{ background: configData.AppColor3 }}
          className="navigation-button"
          onClick={onPrev}
        >
          Back
        </button>
        <button
          style={{ background: configData.AppColor3 }}
          className="navigation-button"
          onClick={onNext}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Step3ExperienceDescription;
