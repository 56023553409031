import { FaFacebook, FaInstagram, FaLinkedin, FaYoutube } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

const FormWrapperFooter = ({configData, style}) => {
    return ( <>
        <div className="step-image-footer" style={style}>
          <div className="formWrapper-image-icon">
            <ul>
              <li>
                <a
                  href={`${configData.faceBook}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaFacebook />
                </a>
              </li>
              <li>
                <a
                  href={`${configData.twiter}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaXTwitter />
                </a>
              </li>
              <li>
                <a
                  href={`${configData.instagram}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaInstagram />
                </a>
              </li>
              <li>
                <a
                  href={`${configData.linkedIn}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaLinkedin />
                </a>
              </li>
              <li>
                <a
                  href={`${configData.youtube}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaYoutube />
                </a>
              </li>
            </ul>
          </div>
          <div className="copyrigtht" style={{alignSelf: 'center'}}>
            <p style={{ fontSize: "15px", color: "#ccc" }}>
              © {new Date().getFullYear()} {configData.copyright}
            </p>
          </div>
        </div>
    </> );
}
 
export default FormWrapperFooter;