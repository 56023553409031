import React, { useState, useEffect } from "react";
import "../../Css/account.css";
import { FaArrowDown, FaHeart } from "react-icons/fa";
import configData from '../../config.json'

const accounts = [
  {
    owner: "Victor chukwujiobi",
    movements: [200, 455.23, -306.5, 25000, -642.21, -133.9, 79.97, 1300],
    interestRate: 1.2,
    pin: 1111,
    movementsDates: [
      "2022-06-18T21:31:17.178Z",
      "2022-06-20T07:42:02.383Z",
      "2022-06-14T09:15:04.904Z",
      "2020-04-01T10:17:24.185Z",
      "2020-05-08T14:11:59.604Z",
      "2020-05-27T17:01:17.194Z",
      "2020-07-11T23:36:17.929Z",
      "2020-07-12T10:51:36.790Z",
    ],
    currency: "USD",
    locale: "en-US",
  },
  {
    owner: "Samuel chukwujiobi",
    movements: [5000, 3400, -150, -790, -3210, -1000, 8500, -30],
    interestRate: 1.5,
    pin: 2222,
    movementsDates: [
      "2022-06-22T13:15:33.035Z",
      "2019-11-30T09:48:16.867Z",
      "2019-12-25T06:04:23.907Z",
      "2020-01-25T14:18:46.235Z",
      "2022-06-17T16:33:06.386Z",
      "2022-06-19T14:43:26.374Z",
      "2020-06-25T18:49:59.371Z",
      "2022-06-21T12:01:20.894Z",
    ],
    currency: "USD",
    locale: "en-US",
  },
];

const formatMovementDate = (date, locale) => {
  const calcDaysPassed = (date1, date2) =>
    Math.round(Math.abs(date2 - date1) / (1000 * 60 * 60 * 24));

  const daysPassed = calcDaysPassed(new Date(), new Date(date));

  if (daysPassed === 0) return "Today";
  if (daysPassed === 1) return "Yesterday";
  if (daysPassed <= 7) return `${daysPassed} days ago`;

  return new Intl.DateTimeFormat(locale).format(new Date(date));
};

const formatCur = (value, locale, currency) => {
  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currency,
  }).format(value);
};

const Account = ({ isOpen }) => {
   // eslint-disable-next-line
  const [currentAccount, setCurrentAccount] = useState(accounts[0]);
  const [movements, setMovements] = useState([]);
  const [balance, setBalance] = useState(0);
  const [summary, setSummary] = useState({ in: 0, out: 0, interest: 0 });
   // eslint-disable-next-line
  const [timer, setTimer] = useState(null);
  const [isSorted, setIsSorted] = useState(false);
  const [sortedMovements, setSortedMovements] = useState([]);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    displayMovements(currentAccount);
    calcDisplayBalance(currentAccount);
    calcDisplaySummary(currentAccount);
  }, [currentAccount]);

  const displayMovements = (acc) => {
    const movs = acc.movements;

    const formattedMovs = movs.map((mov, i) => {
      const type = mov > 0 ? "deposit" : "withdrawal";
      const date = new Date(acc.movementsDates[i]);
      const displayDate = formatMovementDate(date, acc.locale);
      const formattedMov = formatCur(mov, acc.locale, acc.currency);

      return {
        type,
        date: displayDate,
        value: formattedMov,
      };
    });
    const sortedMovementsByDate = formattedMovs.slice().sort((a, b) => {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);
      return dateB - dateA; // Compare dates in descending order
    });

    setMovements(sortedMovementsByDate);
    setSortedMovements(
      [...formattedMovs].sort(
        (a, b) => parseFloat(b.value) - parseFloat(a.value)
      )
    );
  };

  const calcDisplayBalance = (acc) => {
    const balance = acc.movements.reduce((acc, mov) => acc + mov, 0);
    setBalance(balance);
  };

  const calcDisplaySummary = (acc) => {
    const incomes = acc.movements
      .filter((mov) => mov > 0)
      .reduce((acc, mov) => acc + mov, 0);

    const out = acc.movements
      .filter((mov) => mov < 0)
      .reduce((acc, mov) => acc + mov, 0);

    const interest = acc.movements
      .filter((mov) => mov > 0)
      .map((deposit) => (deposit * acc.interestRate) / 100)
      .filter((int) => int >= 1)
      .reduce((acc, int) => acc + int, 0);

    setSummary({ in: incomes, out, interest });
  };
  const renderMovement = (mov, i) => {
    console.log(
      "isSorted",
      isSorted,
      "sortedMovements",
      sortedMovements,
      "movements",
      movements
    );
    return (
      <>
        <div className="movements__row" key={i}>
          <div className={`movements__type movements__type--${mov.type}`}>
            {i + 1} {mov.type}
          </div>
          <div className="movements__date">{mov.date}</div>
          <div className="movements__value">{mov.value}</div>
        </div>
      </>
    );
  };
  useEffect(() => {
    setTimeout(() => setIsVisible(true), 500);
  }, []);

  return (
    <main
      className={`account ${isVisible && "visible"}`}
      style={{ maxWidth: isOpen && "1250px", marginLeft: isOpen && "310px" }}
    >
      {/* --------balance --------- */}
      <div className="balance">
        <div className="balance-wrapper">
          <p className="balance__label">Current balance:</p>
          <p className="balance__value">
            {formatCur(balance, currentAccount.locale, currentAccount.currency)}
          </p>
        </div>
        <p className="balance__date">
          As of <span className="date">05/03/2037</span>
        </p>
        <p className="balance__date" style={{cursor: 'pointer', border: '1px solid #ccc', borderRadius: '10px', padding: '5px', marginTop: '5px'}}>
          <FaHeart style={{marginRight: '5px', color: configData.AppColor}}/>Buy hearts
        </p>
      </div>

      {/* --------movements --------- */}
      <div className="movements">
        {isSorted
          ? sortedMovements.map((mov, i) => renderMovement(mov, i))
          : movements.map((mov, i) => renderMovement(mov, i))}
      </div>

      {/* --------summary --------- */}
      <div className="summary">
        <div style={{ display: "flex" }}>
          <p className="summary__label">In</p>
          <p className="summary__value summary__value--in">
            {formatCur(
              summary.in,
              currentAccount.locale,
              currentAccount.currency
            )}
          </p>
          <p className="summary__label">Out</p>
          <p className="summary__value summary__value--out">
            {formatCur(
              summary.out,
              currentAccount.locale,
              currentAccount.currency
            )}
          </p>
        </div>
        <div style={{ display: "flex" }}>
          <p className="summary__label">Interest</p>
          <p className="summary__value summary__value--interest">
            {formatCur(
              summary.interest,
              currentAccount.locale,
              currentAccount.currency
            )}
          </p>
          <button className="btn--sort" onClick={() => setIsSorted(!isSorted)}>
            {<FaArrowDown />} SORT
          </button>
        </div>
      </div>

      {/* --------operation--transfer --------- */}
      <div className="operation operation--transfer">
        <h2>Transfer hearts</h2>
        <form className="form form--transfer">
          <input type="text" className="form__input form__input--to" />
          <input type="number" className="form__input form__input--amount" />
          <button className="form__btn form__btn--transfer">&rarr;</button>
          <label className="form__label">Transfer to</label>
          <label className="form__label">Amount</label>
        </form>
      </div>

      {/* --------operation--loan --------- */}
      <div className="operation operation--loan">
        <h2>Request loan</h2>
        <form className="form form--loan">
          <input
            type="number"
            className="form__input form__input--loan-amount"
          />
          <button className="form__btn form__btn--loan">&rarr;</button>
          <label className="form__label form__label--loan">Amount</label>
        </form>
        <span className="loan--failed"></span>
      </div>

      {/* --------operation--close --------- */}
      <div className="operation operation--close">
        <h2>Lock account</h2>
        <form className="form form--close">
          <input type="text" className="form__input form__input--user" />
          <input
            type="password"
            maxLength="6"
            className="form__input form__input--pin"
          />
          <button className="form__btn form__btn--close">&rarr;</button>
          <label className="form__label">Confirm user</label>
          <label className="form__label">Confirm PIN</label>
        </form>
      </div>

    </main>
  );
};

export default Account;
