import React from "react";
import configData from "../../../config.json";

const Step6AssessmentEvaluation = ({ data, onInputChange, onPrev, onNext }) => {
  return (
    <div className="form-step" style={{ marginBottom: "25%" }}>
      <h2>Assessment and Evaluation</h2>

      <label>
        Exam Structure <span style={{ color: "red" }}> *</span>
      </label>
      <textarea
        name="examStructure"
        value={data.examStructure}
        onChange={(e) => onInputChange(e)}
        data-step="assessmentEvaluation"
        className="styled-input"
      ></textarea>

      <label>
        Grading Criteria <span style={{ color: "red" }}> *</span>
      </label>
      <textarea
        name="gradingCriteria"
        value={data.gradingCriteria}
        onChange={(e) => onInputChange(e)}
        data-step="assessmentEvaluation"
        className="styled-input"
      ></textarea>

      <div className="button-wrapper">
        <button
          style={{ background: configData.AppColor3 }}
          className="navigation-button"
          onClick={onPrev}
        >
          Back
        </button>
        <button
          style={{ background: configData.AppColor3 }}
          className="navigation-button"
          onClick={onNext}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Step6AssessmentEvaluation;
