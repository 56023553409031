import React from "react";
import "../../../Css/formWrapper.css"; // Import the CSS file
import TypingEffect from "../../utilities/typingText";
import FormWrapperFooter from "./formWrapper-footer";
import { useMediaQuery } from "react-responsive";

const FormWrapper = ({
  texts,
  configData,
  formWrapperImage,
  children,
  isOpen,
  background
}) => {
  const isSmallScreen = useMediaQuery({ maxWidth: "912px" });
  const isMobile = useMediaQuery({ maxWidth: "600px" });

  return (
    <div className={`formWrapper-container ${isOpen && "form-open"}`}>
      <div
        style={{
          display: "flex",
          flexDirection: isSmallScreen ? "column" : "row",
        }}
      >
        <div
          className="formWrapper-image"
          style={{ background: background }}
        >
          <div className="formWrapper-image-cont">
            <img src={formWrapperImage} alt="design for form"/>
            <TypingEffect
              texts={texts}
              initialDelay={0}
              interval={2000}
              style={{ color: "#fff", margin: "15px 0" }}
            />
          </div>
          {!isSmallScreen && <FormWrapperFooter configData={configData}/>}
        </div>
        {children}
      </div>

      {isSmallScreen && (
        <FormWrapperFooter
          configData={configData}
          style={{
            background: background,
            position: "fixed",
            top: "100%",
            width: isMobile ? "100%" : isSmallScreen? "90%" : "100%",
            display: "flex",
            flexDirection: "column",
            borderRadius: "0px",
            padding: "25px 10px",
          }}
        />
      )}
    </div>
  );
};

export default FormWrapper;
