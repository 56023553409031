const checkCookie = (name) => {
    const value = `; ${document.cookie}`;
    console.log('cookie: ', document.cookie)
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
      return true;
    }
    return false; 
  };

  const clearCookie = (name) => {
    document.cookie = `${name}=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT;`;
  };

module.exports = { checkCookie, clearCookie }