function transformDropboxUrl(dropboxUrl) {
  // Check if the URL contains "www.dropbox.com"
  if (dropboxUrl?.includes("www.dropbox.com")) {
    // Extract the unique identifier from the URL
    const identifier = dropboxUrl?.split("/").slice(-3).join("/");
    // Construct the new URL with the desired base URL
    return `https://dl.dropboxusercontent.com/scl/fi/${identifier}`;
  }
  // If the URL does not contain "www.dropbox.com", return it unchanged
  return dropboxUrl;
}

function formatDuration(duration) {
  const minutes = Math.floor(duration / 60);
  const seconds = Math.floor(duration % 60);
  return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
}

const formatEstimatedDuration = (seconds) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);

  let formattedDuration = "about ";
  if (hours > 0) {
    formattedDuration += `${hours}hr${hours > 1 ? "s" : ""} `;
  }
  if (minutes > 0) {
    formattedDuration += `${minutes}min${minutes > 1 ? "s" : ""}`;
  }

  // Handle case when duration is less than a minute
  if (hours === 0 && minutes === 0) {
    formattedDuration += "less than a minute";
  }

  return formattedDuration.trim();
};

const playAudio = (audioId) => {
  const audioPlayer = document.getElementById(`audio-${audioId}`);
  if (audioPlayer) {
    audioPlayer.play();
  }
};
const pauseAudio = (audioId) => {
  const audioPlayer = document.getElementById(`audio-${audioId}`);
  if (audioPlayer) {
    audioPlayer.pause();
  }
};
const reStartAudio = (audioId) => {
  const audioPlayer = document.getElementById(`audio-${audioId}`);
  if (audioPlayer) {
    audioPlayer.pause();
    audioPlayer.currentTime = 0; // Reset the audio to the start
    audioPlayer.play(); // Play the audio
  }
};

module.exports = {
  transformDropboxUrl,
  formatDuration,
  playAudio,
  reStartAudio,
  formatEstimatedDuration,
  pauseAudio
};
