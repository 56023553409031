import React, { useState, useEffect } from 'react';

const TypingEffect = ({ texts, initialDelay, interval, style }) => {
  const [displayedText, setDisplayedText] = useState('');
  const [currentTextIndex, setCurrentTextIndex] = useState(0);

  useEffect(() => {
    const typeText = (text) => {
      return new Promise((resolve) => {
        let index = 0;
        const typingInterval = setInterval(() => {
          setDisplayedText((prev) => prev + text[index]);
          index++;
          if (index === text.length) {
            clearInterval(typingInterval);
            resolve();
          }
        }, 100); // Adjust typing speed here
      }, []);
    };

    const displayTexts = async () => {
      for (let i = currentTextIndex; i < texts.length; i++) {
        setDisplayedText('');
        await typeText(texts[i]);
        if (i < texts.length - 1) {
          await new Promise((resolve) => setTimeout(resolve, interval));
        }
      }
      // Restart the cycle
      await new Promise((resolve) => setTimeout(resolve, initialDelay));
      setCurrentTextIndex(0);
      displayTexts(); // Call displayTexts again to restart the typing effect
    };

    const timerId = setTimeout(displayTexts, initialDelay);
    return () => clearTimeout(timerId); // Clear the timeout when the component unmounts
  }, [texts, initialDelay, interval, currentTextIndex]);

  const typingContainerStyle = {
    borderRight: '2px solid',
    overflow: 'hidden',
    animation: 'typing 2s steps(40, end), blink .75s step-end infinite',
    ...style,
  };

  return <h2 style={typingContainerStyle} className='typingeffect-h2'>{displayedText}</h2>;
};

export default TypingEffect;
