import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Outlet } from "react-router-dom";
import PrivateRoute from "./components/Routing/PrivateRoute";
import Home from "./components/GeneralScreens/Home";
import LoginScreen from "./components/AuthScreens/LoginScreen";
import RegisterScreen from "./components/AuthScreens/RegisterScreen";
import ConfirmEmailAndSignUp from "./components/AuthScreens/confirmEmailAndSignUp";
import ForgotPasswordScreen from "./components/AuthScreens/ForgotPasswordScreen";
import ResetPasswordScreen from "./components/AuthScreens/ResetPasswordScreen";
import AddStory from "./components/StoryScreens/AddStory";
import DetailStory from "./components/StoryScreens/DetailStory";
import Header from "./components/GeneralScreens/Header";
import Footer from "./components/GeneralScreens/Footer";
import Profile from "./components/ProfileScreens/Profile";
import EditProfile from "./components/ProfileScreens/EditProfile";
import ChangePassword from "./components/ProfileScreens/ChangePassword";
import NotFound from "./components/GeneralScreens/NotFound";
import EditStory from "./components/StoryScreens/EditStory";
import ReadListPage from "./components/ProfileScreens/ReadListPage";
import PrivacyPolicy from "./components/GeneralScreens/privacyPolicy";
import TermsOfService from "./components/GeneralScreens/termsOfService";
import JambUTMEExam from "./components/CbtScreens/jambInfo";
import QuestionComponent from "./components/CbtScreens/questionDisplay";
import ResultCard from "./components/CbtScreens/resultTemp";
import TopicByTopicExam from "./components/CbtScreens/topicByTopic";
import CustomExam from "./components/CbtScreens/customExam";
import ExamHistoryList from "./components/CbtScreens/examHistory";
import Search from "./components/GeneralScreens/search";
import AllExams from "./components/CbtScreens/allExams";
import SitemapViewer from "./components/GeneralScreens/sitemap";
import ExamPage from "./components/CbtScreens/allExamDetails";
import CookieNotification from "./components/GeneralScreens/cookieNotification";
import ImageUpload from "./components/image-to-3d/imageUpload";
import VideoTable from "./components/GeneralScreens/youtubeAnal";
import AllAudios from "./components/audioScreens/allAudios";
import CreateAuthorizationToken from "./components/audioScreens/createAuthorizationToken";
import AudioCollectionDetails from "./components/audioScreens/audioDetails";
import CreateAudio from "./components/audioScreens/createAudio";
import Sidebar from "./components/GeneralScreens/sideBar";
import { FaBars } from "react-icons/fa";
import { useMediaQuery } from "react-responsive";
import CreateQuestion from "./components/questionScreens/createQuestions";
import AddQuestion from "./components/questionScreens/add-question/addQuestion";
import Account from "./components/ProfileScreens/account";
import ShareExperienceForm from "./components/experienceScreens/experience";

const App = () => {
  const isMobile = useMediaQuery({ maxWidth: "600px" });
  const [isOpen, setIsOpen] = useState(isMobile ? false : true);
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<LayoutsWithHeader />}>
            <Route path="*" element={<NotFound />} />

            <Route exact path="/" element={<Home />} />
            <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route
              exact
              path="/terms-of-service"
              element={<TermsOfService />}
            />
            <Route exact path="/story/:slug" element={<DetailStory />} />

            <Route exact path="/addstory" element={<PrivateRoute />}>
              <Route exact path="/addstory" element={<AddStory />} />
            </Route>

            
            <Route exact path="/authorizeAudio" element={<PrivateRoute />}>
              <Route
                exact
                path="/authorizeAudio"
                element={<CreateAuthorizationToken />}
              />
            </Route>

            <Route exact path="/story/:slug/like" element={<PrivateRoute />}>
              <Route exact path="/story/:slug/like" element={<DetailStory />} />
            </Route>

            <Route exact path="/story/:slug/edit" element={<PrivateRoute />}>
              <Route exact path="/story/:slug/edit" element={<EditStory />} />
            </Route>

            <Route exact path="/story/:slug/delete" element={<PrivateRoute />}>
              <Route
                exact
                path="/story/:slug/delete"
                element={<DetailStory />}
              />
            </Route>
            <Route
              exact
              path="/story/:slug/addComment"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/story/:slug/addComment"
                element={<DetailStory />}
              />
            </Route>

            <Route exact path="/readList" element={<PrivateRoute />}>
              <Route exact path="/readList" element={<ReadListPage />} />
            </Route>
            <Route exact path="/3dconverter" element={<PrivateRoute />}>
              <Route exact path="/3dconverter" element={<ImageUpload />} />
            </Route>
            <Route exact path="/youtube" element={<VideoTable />} />
          </Route>

          <Route
            path="/"
            element={
              <LayoutComponent
                component={"audio"}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
              />
            }
          >
            <Route
              exact
              path="/audio/find-audio"
              element={<AllAudios isOpen={isOpen} />}
            />
            <Route
              exact
              path="/audio/create-audio"
              element={<CreateAudio isOpen={isOpen} />}
            />
              <Route exact path="/settings/profile" element={<PrivateRoute />}>
              <Route exact path="/settings/profile" element={<Profile />} />
            </Route>
            <Route exact path="/settings/edit_profile" element={<PrivateRoute />}>
              <Route
                exact
                path="/settings/edit_profile"
                element={<EditProfile isOpen={isOpen} />}
              />
            </Route>
            <Route exact path="/settings/account" element={<PrivateRoute />}>
              <Route
                exact
                path="/settings/account"
                element={<Account isOpen={isOpen} />}
              />
            </Route>
            <Route
              exact
              path="/settings/change_Password"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/settings/change_Password"
                element={<ChangePassword isOpen={isOpen} />}
              />
            </Route>
            <Route
              exact
              path="/audio/audio-detail/:audioCollectionId"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/audio/audio-detail/:audioCollectionId/:audioCollectionTitle"
                element={<AudioCollectionDetails isOpen={isOpen} />}
              />
            </Route>
          </Route>
          <Route
            path="/"
            element={
              <LayoutComponent
                component={"questions"}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
              />
            }
          >
            <Route exact path="/question/allExams" element={<PrivateRoute />}>
              <Route
                exact
                path="/question/allExams/details/:slug"
                element={<ExamPage isOpen={isOpen} />}
              />
            </Route>
            {/* <Route exact path="/jamb" element={<PrivateRoute />}> */}
            <Route exact path="/jamb" element={<JambUTMEExam />} />
            {/* </Route> */}
            {/* <Route exact path="/questionDisplay" element={<PrivateRoute />}> */}
            <Route
              exact
              path="/questionDisplay"
              element={<QuestionComponent isOpen={isOpen} />}
            />
            {/* </Route> */}
            {/* <Route exact path="/topicByTopic" element={<PrivateRoute />}> */}
            <Route
              exact
              path="question/topicByTopic"
              element={<TopicByTopicExam isOpen={isOpen} />}
            />
            {/* </Route> */}
            {/* <Route exact path="/customExam" element={<PrivateRoute />}> */}
            <Route
              exact
              path="question/customExam"
              element={<CustomExam isOpen={isOpen} />}
            />
            {/* </Route> */}

            {/* <Route exact path="/resultTemp" element={<PrivateRoute />}> */}
            <Route exact path="/resultTemp" element={<ResultCard />} />
            {/* </Route> */}
            {/* <Route
              exact
              path="/question/create-question"
              element={<PrivateRoute />}
            > */}
            <Route
              exact
              path="/question/create-question"
              element={<CreateQuestion isOpen={isOpen} />}
            />
            {/* </Route> */}
            {/* <Route
              exact
              path="/question/add-question"
              element={<PrivateRoute />}
            > */}
            <Route
              exact
              path="/question/add-question"
              element={<AddQuestion isOpen={isOpen} />}
            />
            {/* </Route> */}
            <Route exact path="/question/allExams" element={<PrivateRoute />}>
              <Route
                exact
                path="/question/allExams"
                element={<AllExams isOpen={isOpen} />}
              />
            </Route>
            <Route
              exact
              path="/question/examHistory"
              element={<PrivateRoute />}
            >
              <Route
                exact
                path="/question/examHistory"
                element={<ExamHistoryList isOpen={isOpen} />}
              />
            </Route>
          </Route>

          <Route exact path="/search" element={<Search />} />
          <Route exact path="/login" element={<LoginScreen />} />
          <Route exact path="/register" element={<RegisterScreen />} />
          <Route
            exact
            path="/confirmEmailAndSignUp/:token"
            element={<ConfirmEmailAndSignUp />}
          />

          <Route
            exact
            path="/forgotpassword"
            element={<ForgotPasswordScreen />}
          />

          <Route
            exact
            path="/resetpassword"
            element={<ResetPasswordScreen />}
          />
          <Route exact path="/sitemap.xml" element={<SitemapViewer />} />
        <Route
            path="/"
            element={
              <LayoutComponent
                component={"questions"}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
              />
            }
          >
              <Route
                exact
                path="/experience/share-experience"
                element={<ShareExperienceForm isOpen={isOpen} />}
              />
          </Route>
        </Routes>
      </div>
    </Router>
  );
};

const LayoutsWithHeader = () => {
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          // height: "100%",
          justifyContent: "space-between",
          position: "relative",
        }}
      >
        <div>
          <div
            style={{
              position: "absolute",
              zIndex: 9999,
              display: "flex",
              background: "#fff",
              width: "100%",
            }}
          >
            <Header />
          </div>
          <div style={{ height: "95px" }}></div>
          <Outlet />
          <CookieNotification />
        </div>
        <Footer />
      </div>
    </>
  );
};
const LayoutComponent = ({ component, isOpen, setIsOpen }) => {
  return (
    <div
      style={{
        overflowX: "hidden",
        overflowY: "auto",
        height: "100vh",
      }}
    >
      <button
        onClick={() => setIsOpen(true)}
        className="toggle-button"
        style={{
          position: "absolute",
          top: "30px",
          left: "10px",
          zIndex: 100,
        }}
      >
        <FaBars />
      </button>
      <div
        style={{
          position: "absolute",
          zIndex: 99,
          display: "flex",
          background: "#fff",
          width: "100%",
        }}
      >
        <Header component={component} isOpen={isOpen} />
      </div>
      <Sidebar isOpen={isOpen} setIsOpen={setIsOpen} />
      <div style={{ height: "95px" }}></div>

      <Outlet />
    </div>
  );
};

export default App;
