import React from "react";
import configData from "../../../config.json";

const Step5EngagementParticipation = ({ data, onInputChange, onPrev, onNext }) => {
  return (
    <div className="form-step" style={{ marginBottom: "25%" }}>
      <h2>Engagement and Participation</h2>

      <label>
        Class Participation <span style={{ color: "red" }}> *</span>
      </label>
      <textarea
        name="classParticipation"
        value={data.classParticipation}
        onChange={(e) => onInputChange(e)}
        data-step="engagementParticipation"
        className="styled-input"
      ></textarea>

      <label>
        Group Work <span style={{ color: "red" }}> *</span>
      </label>
      <textarea
        name="groupWork"
        value={data.groupWork}
        onChange={(e) => onInputChange(e)}
        data-step="engagementParticipation"
        className="styled-input"
      ></textarea>

      <div className="button-wrapper">
        <button
          style={{ background: configData.AppColor3 }}
          className="navigation-button"
          onClick={onPrev}
        >
          Back
        </button>
        <button
          style={{ background: configData.AppColor3 }}
          className="navigation-button"
          onClick={onNext}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Step5EngagementParticipation;
