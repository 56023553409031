import React, { useCallback, useState, useEffect } from "react";
import configData from "../../../config.json";
import formWrapperImage from "../../../img/examHub.png";
import "../../../Css/addQuestion.css";
import FormWrapper from "./formWrapper";
import Step1ChooseMethod from "./step1";
import Step2UploadFile from "./step2";
import Step3ManualForm from "./step3";
import Step4ExamDetails from "./step4";
import { step1Texts } from "../../utilities/data/formText";
import { questionTypes } from "../../utilities/data/data";

const AddQuestion = ({ isOpen }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState({
    method: "",
    file: null,
    questions: [{}],
    examDetails: {
      image: "",
      name: "",
      description: "",
      duration: "",
      grade: "",
      difficulty: "",
      school: "",
    },
  });


  const isValidJSON = (str) => {
    try {
      JSON.parse(str);
      return true;
    } catch (e) {
      return false;
    }
  };
  // localStorage.removeItem("questions")
  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      questions: isValidJSON(localStorage.getItem("questions")) ? JSON.parse(localStorage.getItem("questions")) : [{}],
    }));
  }, []);

  const handleNextStep = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const handlePrevStep = () => {
    setCurrentStep((prevStep) => prevStep - 1);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      examDetails: {
        ...prevData.examDetails,
        [name]: value,
      },
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormData((prevData) => ({ ...prevData, file }));
  };

  const handleMethodChange = (method) => {
    setFormData((prevData) => ({ ...prevData, method }));
  };
  
  const handleSelectMethod = useCallback((method) => {
    handleMethodChange(method)
    if(method === "manual"){
      setCurrentStep(3)
    } else if(method === "ordered" || "unordered"){
      setCurrentStep(2)
    }
    // eslint-disable-next-line
  }, [currentStep])

  return (
    <div className={`multi-step-form ${isOpen ? "open" : "close"}`}>
      {currentStep === 1 && (
        <FormWrapper
          isOpen={isOpen}
          texts={step1Texts}
          configData={configData}
          background={configData.AppColor3}
          formWrapperImage={formWrapperImage}
        >
          <Step1ChooseMethod handleSelectMethod={handleSelectMethod} />
        </FormWrapper>
      )}
      {currentStep === 2 && (
        <FormWrapper
          isOpen={isOpen}
          texts={step1Texts}
          configData={configData}
          background={configData.AppColor3}
          formWrapperImage={formWrapperImage}
        >
          <Step2UploadFile
            method={formData.method}
            onFileChange={handleFileChange}
            onNext={() => setCurrentStep(4)}
            onPrev={handlePrevStep}
          />
        </FormWrapper>
      )}
      {currentStep === 3 && (
        <FormWrapper
          isOpen={isOpen}
        texts={questionTypes.map(type => `${type.label}: ${type.description}`)}
        configData={configData}
        formWrapperImage={formWrapperImage}
        background={configData.AppColor3}
        >
        <Step3ManualForm
          questions={formData.questions}
          onNext={handleNextStep}
          onPrev={() => setCurrentStep(1)}
        />
        </FormWrapper>
      )}
      {currentStep === 4 && (
        <FormWrapper
          isOpen={isOpen}
        texts={step1Texts}
        configData={configData}
        formWrapperImage={formWrapperImage}
        background={configData.AppColor3}
        >
        <Step4ExamDetails
          examDetails={formData.examDetails}
          onInputChange={handleInputChange}
          onPrev={handlePrevStep}
          onSubmit={() => {
            console.log(formData);
            // Submit form data
          }}
          />
          </FormWrapper>
      )}
    </div>
  );
};

export default AddQuestion;
