import React from 'react';
import { Helmet } from 'react-helmet';
import instance from "../../Context/axiosConfig";
import { v4 as uuidv4 } from 'uuid';
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import SkeletonStory from "../Skeletons/SkeletonStory";
import CardStory from "../StoryScreens/CardStory";
import NoStories from "../StoryScreens/NoStories";
import Pagination from "./Pagination";
import "../../Css/Home.css"
import examHub from '../../img/examHub.png'
import topic from '../../img/topic.png'
import study from '../../img/custom.png'
import AudioHub from '../../img/AudioHub.png'
import summarizer from '../../img/summarizer.png'
import courseExperience from '../../img/courseExperience.png'
import { useNavigate } from "react-router-dom"
import ExamCard from "../CbtScreens/examCard";
import configData from '../../config.json';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useMediaQuery } from 'react-responsive';


const Home = () => {
  const search = useLocation().search
  const searchKey = new URLSearchParams(search).get('search')
  const [stories, setStories] = useState([])
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const isLargescreen = useMediaQuery({ maxWidth: '1030px' }); // Adjust the max width based on your breakpoint
  const isMidScreen = useMediaQuery({ maxWidth: '912px' }); // Adjust the max width based on your breakpoint
  const isMobile = useMediaQuery({ maxWidth: '600px' }); // Adjust the max width based on your breakpoint
  
  useEffect(() => {
    // Delay the animation slightly to ensure the slider is fully loaded
    setTimeout(() => {
      // Slide a little and then slide back to the original position
      const slider = document.querySelector('.slick-slider');
      if (slider) {
        slider.classList.add('slider-animation');
        setTimeout(() => {
          slider.classList.remove('slider-animation');
        }, 1000); // Duration of the animation
      }
    }, 500); // Delay before animation starts
  }, []);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: isMobile ? 1 : isMidScreen ? 2 : isLargescreen ? 3 : 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };
  useEffect(() => {
    const getStories = async () => {

      setLoading(true)
      try {

        const { data } = await instance.get(`/story/getAllStories?search=${searchKey || ""}&page=${page}`)

        if (searchKey) {
          navigate({
            pathname: '/',
            search: `?search=${searchKey}${page > 1 ? `&page=${page}` : ""}`,
          });
        }
        
        setStories(data.data)
        localStorage.setItem('stories', JSON.stringify(data.data))
        setPages(data.pages)

        setLoading(false)
      }
      catch (error) {
        setLoading(true)
      }
    }
    getStories()
  }, [setLoading, search, page, navigate, searchKey])


  useEffect(() => {
    setPage(1)
  }, [searchKey])

  
  const handleNavigate = (local) => {
      navigate(`/${local}`)
  }
  
  useEffect(() => {
    window.scrollTo(0, 0)
  },[])
  


  return (
    <div className="Inclusive-home-page">
      <Helmet>
        <title>{`Home | ${configData.Name}`}</title>
        <meta name="description" content="KingsHeart offers fun and easy exam preparation. Learn from animated videos, practice questions, and more. Join today!" />
      </Helmet>

      <Slider {...settings} className="story-card-wrapper" style={{ minHeight: '0px' }}>
        <ExamCard 
          imageSrc={AudioHub}
          title={'Audio Labs'}
          description={` Listen to educational content anytime, anywhere, whether you’re walking, exercising, or cooking. The Audio Hub turns idle moments into valuable learning opportunities, allowing you to make the most of your time. You can now create audio versions of your textbooks with “Audio Hub”.`}          
          buttonText={'Create Audio'}
          onClick={() => {
            handleNavigate('audio/create-audio') 
          }}
          color={'white'}
          background={configData.AppColor}
        />
          <ExamCard 
            imageSrc={examHub}
            title={'Exam Hub'}
            description={`Practice with the “Exam Hub.” This mode offers a variety of exam mockups based on different exam styles and topics, as well as some standard exams. It also provides detailed feedback and explanations to help you improve your skills.`}          
            buttonText={'Take Exam'}
            onClick={() => {
              handleNavigate('question/create-question') 
            }}
            color={'white'}
            background={configData.AppColor3}
          />
        <ExamCard 
          imageSrc={summarizer}
          title={'Summarizer'}
          description={`Quickly get to the essence of lengthy texts with concise summaries using KingsHeart's Summarizer, a powerful tool designed to streamline and enhance your study sessions.`}          
          buttonText={'Create Summary'}
          onClick={() => {
            handleNavigate('summary/create-summary') 
          }}
          color={'white'}
          background={configData.AppColor2}
        />
          <ExamCard 
            imageSrc={courseExperience}
            title={'Share Experience'}
            description={`Here you can share the experiences with each course in the semester to help students of the next semester get a better understanding of what to expect. This feature allows you to provide insights, tips, and detailed accounts of your journey through the course, offering valuable perspectives to those who will follow in your footsteps.`}          
            buttonText={'Share Experience'}
            onClick={() => {
              handleNavigate('allExams') 
            }}
            color={'white'}
            background={configData.AppColor3}
          />
        <ExamCard 
          imageSrc={topic}
          title={'Topic by Topic'}
          description={'If you want to study in a structured and thorough way, you can select the "Topic-by-Topic" mode. This mode will show you all the questions related to a specific subject, so you can master it in depth. This is a great way to learn efficiently and effectively.'}
          buttonText={'Study Mode'}
          onClick={() => {
            handleNavigate('question/topicByTopic')
            localStorage.setItem('examBody', 'topic')
          }}
          color={'white'}
          background={configData.AppColor}
          />
        <ExamCard 
          imageSrc={study}
          title={'Custom Exam'}
          description={`Want to create your own exams based on your preferences? Try the "Custom Exam" mode and choose the questions, topics, and difficulty levels that suit you best. It's a great way to test yourself on what you really want to learn.`}
          buttonText={'Custom Mode'}
          onClick={() => {
            handleNavigate('question/customExam')
            localStorage.setItem('examBody', 'custom')
          }}
          color={'white'}
          background={configData.AppColor2}
          />
        </Slider>
      {loading ?
          <Slider {...settings} className="skeleton_emp" style={{ minHeight: '0px' }}>
          {
            [...Array(6)].map(() => {
              return (
                // theme dark :> default : light
                <SkeletonStory key={uuidv4()} />
              )
            })}
          </Slider>

        :
        <div>
          <div className="blog-caption"><h4>{'Latest Posts>>>'}</h4></div>
          <Slider {...settings} className="story-card-wrapper" style={{ minHeight: '0px' }}>
            {stories.length !== 0 ?
              stories.map((story) => {
                return (
                  <CardStory key={uuidv4()} story={story} />
                )
              }) : <NoStories />
            }

          </Slider>

          <Pagination page={page} pages={pages} changePage={setPage} />

        </div>

      }
      <br />
    </div>

  )

};

export default Home;