import React from "react";
import configData from "../../../config.json";

const Step8PrivacyOptions = ({ data, onInputChange, onPrev, onNext }) => {
  return (
    <div className="form-step" style={{ marginBottom: "25%" }}>
      <h2>Privacy Options</h2>

      <label>
        <input
          type="checkbox"
          name="shareAnonymously"
          checked={data.shareAnonymously}
          onChange={(e) => onInputChange(e)}
          data-step="privacyOptions"
          className="styled-checkbox"
        />
        Share anonymously
      </label>

      <div className="button-wrapper">
        <button
          style={{ background: configData.AppColor3 }}
          className="navigation-button"
          onClick={onPrev}
        >
          Back
        </button>
        <button
          style={{ background: configData.AppColor3 }}
          className="navigation-button"
          onClick={onNext}
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default Step8PrivacyOptions;
