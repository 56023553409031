import React, { useState } from 'react';
import { FaHome, FaHeadphones, FaCog, FaFileAlt, FaTimes } from 'react-icons/fa';
import logo from '../../img/logo-icon.png';
import '../../Css/sideBar.css';
import { useNavigate, Link } from 'react-router-dom';
import configData from '../../config.json';
import { useLocation } from 'react-router-dom';

const Sidebar = ({isOpen, setIsOpen}) => {
  const navigate = useNavigate();
  const location = useLocation(); // Get the current location
  const [activeIndex, setActiveIndex] = useState(null);

  const handleAccordionClick = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  // Function to check if the current path matches the link's path
  const isActive = (path) => {
    return location.pathname === path;
  };

  const isAccordionIsActive = (path) => {
    return location.pathname.startsWith(path);
  };

  const activeStyle = (path) => {
    return {
      background: isActive(path) ? "#ccc" : "",
      borderRadius: '10px'
    };
  };

  return (
    <div className={`sidebar ${isOpen ? 'open' : 'close'}`}>
      <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
        <div className='logo-container-sidebar' onClick={() => navigate('/')}>
          <Link to="/">
            <img src={logo} alt='logo' className="logo-sidebar"/>
          </Link>
          <h3 className='logo-title-sidebar'>Kingsheart</h3>
        </div>
        <button onClick={() => setIsOpen(false)} className="toggle-button">
          <FaTimes />
        </button>
      </div>
      <hr></hr>
      <div className="sidebar-item" style={{marginTop: '30px'}}>
        <button className="regular" onClick={() => navigate('/')} style={activeStyle('/')}>
          <FaHome className="icon" /> Home
        </button>
      </div>
      <div className="sidebar-item">
        <button
          className={`accordion ${activeIndex === 1 ? 'active' : ''}`}
          onClick={() => handleAccordionClick(1)}
          style={{color: isAccordionIsActive('/audio') ? configData.AppColor : ''}}
        >
          <FaHeadphones className="icon" /> AudioLabs
        </button>
        <div className={`panel ${activeIndex === 1 ? 'show' : ''}`}>
          <Link to='/audio/create-audio' style={activeStyle('/audio/create-audio')}>Create Audio</Link>
          <Link to='/audio/find-audio' style={activeStyle('/audio/find-audio')}>Find Audio</Link>
          <Link to='#' style={activeStyle('/audio/my-audios')}>My Audios</Link>
          <Link to='#' style={activeStyle('/audio/my-audio-notes')}>My Audio Notes</Link>
        </div>
      </div>
      <div className="sidebar-item">
        <button
          className={`accordion ${activeIndex === 2 ? 'active' : ''}`}
          onClick={() => handleAccordionClick(2)}
          style={{color: isAccordionIsActive('/question') ? configData.AppColor3 : ''}}
        >
          <FaFileAlt className="icon" /> Exam Hub
        </button>
        <div className={`panel ${activeIndex === 2 ? 'show' : ''}`}>
          <Link to='/question/create-question' style={activeStyle('/question/create-question')}>Create Questions</Link>
          <Link to='/question/my-questions' style={activeStyle('/question/my-questions')}>My Questions</Link>
          <Link to='/question/allExams' style={activeStyle('/question/allExams')}>All Exams</Link>
          <Link to='/question/topicByTopic' style={activeStyle('/question/topicByTopic')}>Topic-By-Topic Exams</Link>
          <Link to='/question/customExam' style={activeStyle('/question/customExam')}>Custom Exams</Link>
          <Link to='/question/examHistory' style={activeStyle('/question/examHistory')}>Exam History</Link>
        </div>
      </div>
      <div className="sidebar-item">
        <button
          className={`accordion ${activeIndex === 3 ? 'active' : ''}`}
          onClick={() => handleAccordionClick(3)}
          style={{color: isAccordionIsActive('/summary') ? configData.AppColor2 : ''}}
        >
          <FaFileAlt className="icon" /> Summarizer
        </button>
        <div className={`panel ${activeIndex === 3 ? 'show' : ''}`}>
          <Link to='#' style={activeStyle('/summary/create-summary')}>Create Summary</Link>
          <Link to='#' style={activeStyle('/summary/my-summaries')}>My Summaries</Link>
          <Link to='#' style={activeStyle('/summary/all-summaries')}>All Summaries</Link>
        </div>
      </div>
      <div className="sidebar-item">
        <button
          className={`accordion ${activeIndex === 4 ? 'active' : ''}`}
          onClick={() => handleAccordionClick(4)}
          style={{color: isAccordionIsActive('/experience') ? configData.AppColor2 : ''}}
        >
          <FaFileAlt className="icon" />Experience
        </button>
        <div className={`panel ${activeIndex === 4 ? 'show' : ''}`}>
          <Link to='/experience/share-experience' style={activeStyle('/experience/share-experience')}>Share Experience</Link>
          <Link to='#' style={activeStyle('/experience/find-discussions')}>Find Discussions</Link>
        </div>
      </div>
      <div className="sidebar-item">
        <button
          className={`accordion ${activeIndex === 5 ? 'active' : ''}`}
          onClick={() => handleAccordionClick(5)}
          style={{color: isAccordionIsActive('/settings') ? configData.AppColor : ''}}
        >
          <FaCog className="icon" /> Settings
        </button>
        <div className={`panel ${activeIndex === 5 ? 'show' : ''}`}>
          <Link to='/settings/account' style={activeStyle('/settings/account')}>Account</Link>
          <Link to='/settings/profile' style={activeStyle('/settings/profile')}>Profile</Link>
          <Link to='/settings/preferences' style={activeStyle('/settings/preferences')}>Preferences</Link>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
