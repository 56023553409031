import React, { useState } from "react";
import { FaFolderOpen } from "react-icons/fa"; // Import the folder icon from react-icons/fa
import configData from "../../../config.json";
import { FaInfoCircle } from "react-icons/fa";
import { questionTypes } from "../../utilities/data/data";

const Step2UploadFile = ({ method, onFileChange, onNext, onPrev }) => {
  const [questionCategory, setQuestionCategory] = useState({});
  const [hoveredStyle, setHoveredStyle] = useState(null);
  // const [difficulty, setDifficulty] = useState();

  const handlequestionCategoryChange = (event, state) => {
    if (state === "difficulty") {
      // setDifficulty(event.target.value);
    } else {
      setQuestionCategory(event.target.value);
    }
  };

  const isValidJSON = (str) => {
    try {
      JSON.parse(str);
      return true;
    } catch (e) {
      return false;
    }
  };
  return (
    <div className="form-step" style={{marginBottom: '25%'}}>
      <h2>Upload {method === "ordered" ? "Ordered" : "Unordered"} Document</h2>
      <label>
        Question Type <span style={{ color: "red" }}> *</span>
      </label>
      <div className="styled-select-container">
        <select
          value={questionCategory}
          onChange={handlequestionCategoryChange}
          className="styled-select"
        >
          {questionTypes.map((questionType) => (
            <option
              key={questionType.value}
              value={JSON?.stringify(questionType)}
            >
              {questionType.label}
            </option>
          ))}
        </select>
        <FaInfoCircle
          className="info-icon"
          style={{ color: configData.AppColor3 }}
          onMouseEnter={() => {
            if (isValidJSON(questionCategory)) {
              setHoveredStyle(JSON.parse(questionCategory));
            } else {
              setHoveredStyle({
                value: "multiple-choice",
                label: "Multiple Choice Questions",
                description:
                  "A type of question where respondents are provided with multiple answer options, typically only one of which is correct. Useful for assessing knowledge on specific topics with clear, predefined answers.",
              });
            }
          }}
          onMouseLeave={() => setHoveredStyle(null)}
        />
        {hoveredStyle ? (
          <div className="audio-tooltip" style={{ top: "80%", right: 0 }}>
            {hoveredStyle?.description}
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="file-upload">
        <input
          type="file"
          id="file"
          onChange={onFileChange}
          style={{ display: "none" }}
        />
        <label
          htmlFor="file"
          className="file-upload-label"
          style={{ background: configData.AppColor3, color: "#fff" }}
        >
          <FaFolderOpen style={{ fontSize: "20px" }} /> Choose File
        </label>
      </div>
      {method === "unordered" && (
        <p className="note">
          Note <span style={{ color: "red" }}>*</span>: The accuracy of
          questions extracted is dependent on the image quality or clarity of
          the handwriting
        </p>
      )}
      <div className="button-wrapper">
        <button
          style={{ background: configData.AppColor3 }}
          className="navigation-button"
          onClick={onPrev}
        >
          Back
        </button>
        <button
          style={{ background: configData.AppColor3 }}
          className="navigation-button"
          onClick={onNext}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Step2UploadFile;
