import React, { useState, useEffect, useContext, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import SearchForm from "./SearchForm";
import "../../Css/Header.css";
import { FaUserEdit } from "react-icons/fa";
import { FaHistory } from "react-icons/fa";
import { BiLogOut } from "react-icons/bi";
import { BsBookmarks } from "react-icons/bs";
import SkeletonElement from "../Skeletons/SkeletonElement";
import { AuthContext } from "../../Context/AuthContext";
import configData from "../../config.json";
import logo from "../../img/logo-icon.png";
import { FaSearch } from "react-icons/fa";
import { useMediaQuery } from "react-responsive";
import slugify from "slugify";
import { FaHeadphones, FaQuestion, FaFileAlt } from "react-icons/fa";
import { clearCookie } from "../utilities/helperFunc/chechCookie";

const Header = ({ component, isOpen }) => {
  const isSmallScreen = useMediaQuery({ maxWidth: "1030px" });
  const { activeUser, bool } = useContext(AuthContext);
  const [auth, setAuth] = useState(bool);
  const [loading, setLoading] = useState(true);
  const [colorNumber, setColorNumber] = useState(1)
  const navigate = useNavigate();
  const [hidden, setHidden] = useState(true)

  useEffect(() => {
    setAuth(bool);
    setTimeout(() => {
      setLoading(false);
    }, 1600);
  }, [bool]);

  useEffect(() => {
    if(component === 'summarize'){
        setColorNumber(2)
    }else if(component === 'questions'){
        setColorNumber(3)
    }else{
        setColorNumber(1)
    }
  }, [component])


  const handleLogout = () => {
    clearCookie('token');
    setAuth(false)
    navigate("/");
  };

  const [suggestions, setSuggestions] = useState([]);
  const navigateEvent = useMemo(() => new Event("navigateEvent"), []);

  useEffect(() => {
    const handleStorageChange = () => {
      // Update state or take other actions when local storage changes
      const suggestionArray = JSON?.parse(localStorage.getItem("suggestions"));
      setSuggestions(suggestionArray);
    };

    // Listen for the custom event
    window.addEventListener("storageChange", handleStorageChange);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("storageChange", handleStorageChange);
    };
  }, []);

  const makeSlug = (title) => {
    return slugify(title, {
      replacement: "-",
      remove: /[*+~.()'"!:@/?]/g,
      lower: true,
      strict: false,
      locale: "tr",
      trim: true,
    });
  };
  const handleNavigation = (local) => {
    const titleSlug = makeSlug(local);
    navigate(`/story/${titleSlug}`);
    setSuggestions([]);
    window.dispatchEvent(navigateEvent);
  };
  
  const colorStyle = {
    color: configData[`AppColor${colorNumber}`],
    borderColor: configData[`AppColor${colorNumber}`],
  }
  return (
    <header>
      <div
        className={`averager ${isOpen ? "open" : "close"}`}
        style={{
          width: isSmallScreen && component
            ? "calc(100% - 40px)"
            : isOpen? "calc(100% - 255px)":component && "calc(100% - 40px)",
          marginLeft: !component || isSmallScreen ? "auto" : "0px",
          marginRight: !component || isSmallScreen ? "auto" : "10px",
          paddingLeft: component && "35px",
        }}
      >
        {!isOpen && (!component || isSmallScreen) ? (
          <div className="logo_container">
            <Link to="/">
              <img src={logo} alt="logo" className="logo" />
            </Link>
            <h2 className="logo-title">
              K<span style={{ color: configData.AppColor }}>i</span>ngs
              <span style={{ color: configData.AppColor }}>h</span>eart
            </h2>
          </div>
        ) : (
          ""
        )}
        <div className="search-wrapper">
          <SearchForm />
          {suggestions.length > 0 ? (
            <ul className="suggestion-display">
              {suggestions.map((suggestion, index) => (
                <li
                  key={index}
                  onClick={() => handleNavigation(suggestion?.title)}
                >
                  {suggestion?.title}
                </li>
              ))}
            </ul>
          ) : (
            ""
          )}
        </div>
        {isSmallScreen && (
          <div
            style={{
              display: "flex",
              width: "100%",
              padding: "10px",
              justifyContent: "flex-end",
            }}
          >
            <FaSearch
              style={{ fontSize: "16px", color: "#333" }}
              onClick={() => navigate("/search")}
            />
          </div>
        )}
        <div className="header_options">
          {auth ? (
            <div className="auth_options">
              {component ? (
                ""
              ) : (
                <Link
                  className="addStory-link"
                  style={{
                    color: `${configData.AppColor}`,
                    borderColor: `${configData.AppColor}`,
                  }}
                  to="/addstory"
                >
                  AddStory
                </Link>
              )}
              {isSmallScreen ? (
                ""
              ) : (
                <>
                  {component === "audio" ? (
                    <Link
                      className="addStory-link"
                      style={{
                        color: `${configData.AppColor}`,
                        borderColor: `${configData.AppColor}`,
                      }}
                      to="/create-audio"
                    >
                      Create Audio
                    </Link>
                  ) : (
                    ""
                  )}
                  {component === "summarize" ? (
                    <Link
                      className="addStory-link"
                      style={{
                        color: `${configData.AppColor2}`,
                        borderColor: `${configData.AppColor2}`,
                      }}
                      to="/summarizer"
                    >
                      Summarize
                    </Link>
                  ) : (
                    ""
                  )}
                  {component === "questions" ? (
                    <Link
                      className="addStory-link"
                      style={{
                        color: `${configData.AppColor3}`,
                        borderColor: `${configData.AppColor3}`,
                      }}
                      to="/create-question"
                    >
                      Create Question
                    </Link>
                  ) : (
                    ""
                  )}
                </>
              )}

              <div 
              className="header-profile-wrapper" 
              onClick={() => setHidden(!hidden)}
              >
                {loading ? (
                  <SkeletonElement type="minsize-avatar" />
                ) : (
                  <img src={activeUser.photo} alt={activeUser.username} />
                )}

                <div className="sub-profile-wrap" style={{display: !hidden ? 'flex' : 'none'}}>
                  <Link
                    className="profile-link"
                    style={colorStyle}
                    to="settings/profile"
                    onClick={() => setHidden(true)}
                  >
                    {" "}
                    <FaUserEdit /> Profile{" "}
                  </Link>
                  <Link
                    className="profile-link"
                    style={colorStyle}
                    to="/audio/create-audio"
                    onClick={() => setHidden(true)}
                  >
                    <FaHeadphones /> Audio Labs
                  </Link>
                  <Link
                    className="profile-link"
                    style={colorStyle}
                    to="/question/create-question"
                    onClick={() => setHidden(true)}
                  >
                    <FaQuestion /> Exam Hub
                  </Link>
                  <Link
                    className="profile-link"
                    style={colorStyle}
                    to="summarize/create-summary"
                    onClick={() => setHidden(true)}
                  >
                    <FaFileAlt /> Summarizer
                  </Link>
                  <Link
                    className="profile-link"
                    style={colorStyle}
                    to="question/examHistory"
                    onClick={() => setHidden(true)}
                  >
                    {" "}
                    <FaHistory /> Exam History{" "}
                  </Link>
                  <Link
                    className="profile-link"
                    style={colorStyle}
                    to="/readList"
                    onClick={() => setHidden(true)}
                  >
                    <BsBookmarks /> Read List
                  </Link>

                  <button className="logout-btn" onClick={handleLogout}>
                    {" "}
                    <BiLogOut /> Logout
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div className="noAuth_options">
              <Link className="login-link" to="/login">
                {" "}
                Login{" "}
              </Link>

              <Link
                className="register-link"
                style={colorStyle}
                to="/register"
              >
                {" "}
                Register
              </Link>
            </div>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
