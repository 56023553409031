import React, { useState, useEffect, useCallback, useContext } from "react";
import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import instance from "../../Context/axiosConfig";
import { AuthContext } from "../../Context/AuthContext";
import "../../Css/AddStory.css";
import configData from "../../config.json";
import { MdOutlinePhoto } from "react-icons/md"; // Import the photo icon

const RichTextEditor = ({
  setData,
  content, // Get the content directly from props
  currentQuestionIndex,
  placeholder,
  style,
  inputColor,
  fileId
}) => {
  const { config } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [editorContent, setEditorContent] = useState(content);

  useEffect(() => {
    setEditorContent(content);
  }, [content]);

  const handleChange = useCallback((value) => {
    setEditorContent(value);
    setData(value);
     // eslint-disable-next-line
  }, [setData, currentQuestionIndex]);

  const handleImageUpload = async (e) => {
    const input = e.target;
    setIsLoading(true);

    try {
      if (input.files && input.files[0]) {
        const imageData = new FormData();
        imageData.append("image", input.files[0]);

        // Send the imageUrl to your server for storage
        const response = await instance.post(
          "/story/addImage",
          imageData,
          config
        );
        if (response.data.success) {
          setSuccess("image saved");
          setIsLoading(false);
          const imageUrl = response.data.url;
          // Update the content with the image tag and additional styling
          const updatedContent = `${editorContent} <img src="${imageUrl}" alt="Uploaded Image"/>`;
          setEditorContent(updatedContent);
          setData(currentQuestionIndex, updatedContent);
          input.value = null;
          setTimeout(() => {
            setSuccess(null);
          }, 4000);
        } else if (!response.data.success) {
          setIsLoading(false);
          input.value = null;
          setSuccess(null);
          setError("this image was not saved");
          setTimeout(() => {
            setError(null);
          }, 4000);
        }
      }
    } catch (e) {
      setIsLoading(false);
      input.value = null;
      setSuccess(null);
      setError("something went wrong");
      setTimeout(() => {
        setError(null);
      }, 4000);
    }
  };

  const modules = {
    toolbar: {
      container: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ["bold", "italic", "underline", "strike"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link", "blockquote", "code-block"], // Add 'image' to include the default image button
        [{ color: [] }, { background: [] }], // Text color and background color
        [{ align: [] }, "clean", { indent: "-1" }, { indent: "+1" }],
      ],
    },
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "list",
    "bullet",
    "link",
    "image",
    "code-block",
    "color",
    "background",
    "clean",
    "blockquote",
    "indent",
    "align",
  ];

  const quillRef = React.useRef();

  useEffect(() => {
    localStorage.setItem("EditedContent", editorContent);
  }, [editorContent]);

  return (
    <div style={{ position: "relative" }}>
      <div
        style={{
          marginBottom: "2px",
          position: "absolute",
          right: "70%",
          top: isLoading || success || error ? style.top1 : style.top2,
        }}
      >
        <input
          type="file"
          id={fileId}
          onChange={handleImageUpload}
          style={{ display: "none" }}
        />
        <label htmlFor={fileId} style={{ background: "none" }}>
          <MdOutlinePhoto style={{ fontSize: "20px", cursor: "pointer" }} />
        </label>
      </div>
      {error || success ? (
        <span style={{ color: success ? "green" : "red"}}>
          {error || success}
        </span>
      ) : (
        ""
      )}
      {isLoading ? (
        <span style={{ color: inputColor || configData.AppColor}}>
          Uploading...
        </span>
      ) : (
        ""
      )}
      <ReactQuill
        ref={quillRef}
        value={editorContent}
        onChange={handleChange}
        modules={modules}
        formats={formats}
        className="react-quill"
        placeholder={placeholder || "Start writing..."}
        theme="snow"
        style={style}
      />
    </div>
  );
};

export default RichTextEditor;
